export default function useFlowMeta(
  flow: Flow | Ref<Flow>,
  view?: FlowCardView,
) {
  const { getMarketplaceDetailLinkTo } = useMarketplace()
  const { planLevel } = useCurrentSubscription()

  const _flow = computed(() => unref(flow))
  const isLocked = computed(() => planLevel.value < _flow.value.planLevel)
  const isComingSoon = computed(() => _flow.value.status === 'coming_soon')
  const isInProgress = computed(() =>
    ['started', 'waiting'].includes(_flow.value.status),
  )
  const needsSetup = computed(() =>
    view === 'member'
      ? ['waiting', 'warning'].includes(_flow.value.status)
      : _flow.value.status === 'started',
  )

  const flowPath = computed(() => {
    switch (view) {
      case 'admin':
        return needsSetup.value
          ? wrapPathWithOrg(`/org-flows/${_flow.value.id}/setup`)
          : wrapPathWithOrg(`/org-flows/${_flow.value.id}`)
      case 'marketplace':
        return getMarketplaceDetailLinkTo(_flow.value.slug)
      case 'member':
        return needsSetup.value
          ? wrapPathWithOrg(`/user-flows/${_flow.value.id}/setup`)
          : wrapPathWithOrg(`/user-flows/${_flow.value.id}`)
      default:
        return null
    }
  })

  const statusColor = computed(() => {
    if (isInProgress.value) {
      return 'text-atro-blue'
    }
    switch (_flow.value.status) {
      case 'ok':
        return 'text-atro-green'
      case 'warning':
        return 'text-atro-red'
      default:
        return 'text-primary'
    }
  })

  const statusIcon = computed<IconName | null>(() => {
    if (isInProgress.value) {
      return 'circle-rotate'
    }
    switch (_flow.value.status) {
      case 'ok':
        return 'circle-check'
      case 'warning':
        return 'exclamation-circle'
      default:
        return null
    }
  })

  return {
    isComingSoon,
    isLocked,
    isInProgress,
    flowPath,
    needsSetup,
    statusColor,
    statusIcon,
  }
}
