const SKIP_ON_ROUTES = [
  'logout',
  'welcome',
  'orgs-slug-dashboard',
  'orgs-slug-org-flows-id',
  'orgs-slug-org-flows-id-setup',
  'orgs-slug-user-flows-id',
  'orgs-slug-user-flows-id-setup',
  'signup-connect',
]

export default defineNuxtRouteMiddleware((to) => {
  const { user } = useCurrentUser()
  const { shouldConnectUser, isOnboarding } = useOnboarding()

  if (user.value && !SKIP_ON_ROUTES.includes(to.name as string)) {
    if (shouldConnectUser.value) {
      return navigateTo('/signup/connect')
    } else if (isOnboarding.value) {
      return navigateTo({
        path: '/welcome',
        query: to.params.slug ? { org: to.params.slug } : {},
      })
    }
  }
})
