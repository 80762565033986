import * as mutations from '@/mutations'
type Mutations = typeof mutations

export function useMutation<T>(mutationName: keyof Mutations) {
  const pending = ref(false)
  const _mutate = mutations[mutationName]
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  const mutate = async (
    body?: any,
    {
      params,
      query,
    }: {
      params?: Record<string, any>
      query?: Record<string, string>
    } = {},
  ) => {
    pending.value = true
    const response = _mutate({
      body,
      // @ts-ignore TODO: figure out query
      query,
      // @ts-ignore TODO: figure out params
      params: {
        ...(org.value && { orgId: org.value.id }),
        ...(user.value && { userId: user.value.id }),
        ...params,
      },
    }) as Promise<T>
    pending.value = false
    return response
  }

  return {
    mutate,
    mutationPending: pending,
  }
}

export default useMutation
