<template>
  <AtroContent>
    <AtroHeading
      semibold
      class="mb-6 text-center text-atro-gray"
      text="Tell us about your company"
    />

    <Form v-model="formData" input-width="full" @submit="onSubmit">
      <FormKit
        autofocus
        type="text"
        name="name"
        label="Company name"
        validation="required"
      />
      <FormKit
        type="select"
        label="Size"
        name="size"
        placeholder="Number of workers"
        validation="required"
        :options="COMPANY_SIZE_OPTIONS"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton block :pending text="Continue" :disabled="!valid" />
      </template>
    </Form>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  token?: string
}

const { token } = defineProps<Props>()
const emit = defineEmits<{
  complete: [org: Org]
}>()

const { openModal } = useModal()
const { query } = useRoute()

const formData = ref({
  name: '',
  size: '',
})

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(
  async (data) => {
    const path = query.parent ? `/api/orgs/${query.parent}/orgs` : '/api/orgs'
    if (query.parent) {
      openModal('confirm', {
        props: {
          bodyText:
            'Creating this account will add them to your licenses being billed on your next billing cycle',
          confirmActionText: 'Activate',
          headingText: 'Activate Account',
          size: 'md',
          onConfirm: async () => {
            await postData(path, data)
          },
        },
      })
    } else {
      await postData(path, data)
    }
  },
)

async function postData(path: string, data: any) {
  const newOrg = await $post<Org>(path, {
    ...data,
    ...(token ? { inviteCode: token } : {}),
  })
  emit('complete', newOrg)
}
</script>
