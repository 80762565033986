<template>
  <FlowAtomListSubitemStatus
    v-if="type === 'status'"
    v-bind="data"
    @action="(subAction) => onAction(subAction)"
  />

  <FlowAtomListSubitemText
    v-else-if="type === 'text'"
    v-bind="passThroughProps"
    @action="(subAction) => onAction(subAction)"
  />

  <FlowAtomListSubitemUser
    v-else-if="type === 'user'"
    v-bind="passThroughProps"
    :user="data"
    @action="(subAction) => onAction(subAction)"
  />
</template>

<script setup lang="ts">
export interface Props {
  type: 'status' | 'text' | 'user'

  action?: FlowAction
  actionLabel?: string
  actionIcon?: IconName
  badge?: FlowAtomBadge
  data?: any
  postfix?: any
}

const props = defineProps<Props>()
const passThroughProps = { ...props, type: undefined }
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function onAction(subAction?: FlowAction) {
  const _action = subAction || props.action
  _action && emit('action', _action)
}
</script>
