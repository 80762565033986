export default function useActions(actions: Action[]) {
  // ACTIONS BY STATUS
  const activeActions = computed(() =>
    actions.filter((action) => action.status === 'active'),
  )
  const inactiveActions = computed(() =>
    actions.filter((action) => action.status !== 'active'),
  )
  // ACTIVE ACTIONS BY TYPE
  const alertActions = computed(() =>
    activeActions.value.filter((action) => action.type === 'alert'),
  )
  const setupActions = computed(() =>
    activeActions.value.filter((action) => action.type === 'setup'),
  )
  // HELPERS
  const hasInactiveActions = computed(() => inactiveActions.value.length > 0)
  const hasNoActiveActions = computed(
    () => (activeActions.value?.length || 0) === 0,
  )

  return {
    activeActions,
    alertActions,
    hasInactiveActions,
    hasNoActiveActions,
    inactiveActions,
    setupActions,
  }
}
