<template>
  <div class="w-full">
    <AtroMarkdown :prose :markdown="_markdown" v-bind="$attrs" />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  markdown?: string
  text?: string
  prose?: boolean
}

defineOptions({
  inheritAttrs: false,
})
const { markdown, text, prose = true } = defineProps<Props>()

const _markdown = computed(() => markdown || text || '')
</script>
