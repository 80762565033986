<template>
  <UploadZone v-model="model" v-bind="context?.attrs" />
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const model = computed({
  get() {
    return context._value
  },
  set(value) {
    context.node.input(value)
  },
})
</script>
