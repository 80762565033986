export default function usePages(
  flow: Ref<Flow | OrgFlow | null> | Flow | OrgFlow,
  pagesType: FlowPageType,
) {
  const pages = computed(() => {
    const _flow = unref(flow)
    const pageSlugs = _flow?.[pagesType] || []
    return pageSlugs?.map((pageSlug: string) =>
      _flow?.pages.find((page) => page.slug === pageSlug),
    ) as Page[]
  })

  return { pages }
}
