<template>
  <AtroContent :class="['top-0 z-10 h-12 w-full px-4 py-2', { sticky }]">
    <slot />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  sticky?: boolean
}

defineProps<Props>()
const globalState = useGlobalState()

onMounted(() => {
  globalState.value.appBannerShown = true
})

onUnmounted(() => {
  globalState.value.appBannerShown = false
})
</script>
