export default function useCurrentRole() {
  const { org } = useCurrentOrg()

  const isAdmin = computed(() => org.value?.userRole === 'admin')
  const isMember = computed(() => org.value?.userRole === 'member')
  const isOwner = computed(() => org.value?.userRole === 'owner')
  const role = computed(() => org.value?.userRole || 'member')

  return {
    isAdmin,
    isMember,
    isOwner,
    role,
  }
}
