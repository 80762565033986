<template>
  <AtroContent
    v-auto-animate
    v-scroller="{ inset: true, warm }"
    id="flow-main-content-card"
    items="strech"
    wrap="nowrap"
    :class="[
      '!static min-h-full w-full flex-1 p-0 sm:p-12',
      {
        'h-full !py-6 px-4 sm:!px-6': modal,
        'rounded-5xl bg-white shadow-card': !modal,
        'justify-start': justify === 'start',
        'justify-center': justify === 'center',
        'justify-end': justify === 'end',
      },
    ]"
    :ref="setScrollingRef"
  >
    <slot />
  </AtroContent>
</template>

<script setup lang="ts">
import { useAutoAnimate } from '@formkit/auto-animate/vue'

export interface Props {
  animated?: boolean
  justify?: 'start' | 'center' | 'end'
  modal?: boolean
  warm?: boolean
}

const { modal, animated = true, justify = 'center' } = defineProps<Props>()

const [animateTarget, enableAnimations] = useAutoAnimate()
const { scrollingEl } = usePageState()

function setScrollingRef(cmp: any) {
  if (cmp) {
    animateTarget.value = cmp.$el ? cmp.$el : cmp
    scrollingEl.value = cmp.$el ? cmp.$el : cmp
  }
}

onMounted(() => enableAnimations(animated))
</script>
