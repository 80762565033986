type UseFetchOptions<T> = Parameters<typeof useFetch<T>>[1]
type Options<T> = UseFetchOptions<T> & {
  cached?: boolean
  pendingUntilRefresh?: boolean
}

type DataParams = Record<string, unknown>

export default function useData<T>(
  pathname: keyof typeof apiPaths | null | false,
  config: {
    params?: Ref<DataParams> | DataParams | null
    options?: Options<T>
  } = { params: {}, options: {} },
) {
  if (!pathname) return null

  const app = useNuxtApp()
  const headers = useRequestHeaders(['cookie']) as HeadersInit
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  // Memoize the base params to avoid unnecessary recomputation
  const baseParams = computed(() => ({
    ...(org.value?.id && { orgId: org.value.id }),
    ...(user.value?.id && { userId: user.value.id }),
  }))

  // Memoize the merged params
  const _params = computed(() => ({
    ...baseParams.value,
    ...unref(config.params),
  }))

  const key = config.options?.key || getApiResourceKey(pathname, _params)
  const hasFetched = useState<boolean>(key, () => !!import.meta.server)

  // Create fetch options once
  const fetchOptions: UseFetchOptions<T> = {
    headers,
    key,
    dedupe: 'defer',
    ...(config.options?.cached
      ? { getCachedData: () => app.payload.data[key] }
      : {}),
    ...config.options,
  }

  const request = useFetch<T>(
    () => getApiPath(pathname, _params.value),
    fetchOptions,
  ) as FetchRequest<T>

  request.hasFetched = hasFetched

  // Optimize isEmpty check with early returns and type guards
  request.isEmpty = computed(() => {
    if (request.status.value !== 'success' || !hasFetched.value) {
      return false
    }

    const data = request.data.value
    if (data == null) return true
    if (Array.isArray(data)) return data.length === 0
    if (typeof data === 'object') return Object.keys(data).length === 0
    return false
  })

  // Simplified loading state computation
  request.isLoading = computed(() => {
    const isPending = request.status.value === 'pending'
    const shouldShowPending =
      !hasFetched.value || config.options?.pendingUntilRefresh
    return isPending && shouldShowPending
  })

  // More efficient watch using watchEffect
  if (import.meta.client) {
    watchEffect(() => {
      if (!hasFetched.value && request.status.value !== 'pending') {
        hasFetched.value = true
      }
    })
  }

  return request
}
