export default function useWindowScrollState() {
  const scrollY = useState<number>('windowScrollY', () => 0)

  function clearWindowScroll() {
    scrollY.value = 0
  }

  function saveWindowScroll() {
    scrollY.value = window.scrollY
  }

  return { scrollY, clearWindowScroll, saveWindowScroll }
}
