<template>
  <AtroModal size="sm">
    <AtroModalHeader
      icon-wrapped
      icon-class="text-atro-green"
      :icon="passwordChanged ? 'check' : undefined"
      :text="heading"
    />

    <Form
      v-if="!passwordChanged"
      v-model="formData"
      align="center"
      width="sm"
      @submit="onSubmit"
    >
      <FormKit
        name="oldPassword"
        type="password"
        label="Old password"
        validation="required"
      />
      <FormKit
        name="password"
        type="password"
        label="New password"
        validation="required"
      />
      <FormKit
        name="passwordConfirmation"
        type="password"
        label="Confirm new password"
        validation="required|confirm:password"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton :pending text="Update Password" :disabled="!valid" />
      </template>
    </Form>

    <template v-if="passwordChanged" #actions="{ close }">
      <AtroButton block text="Ok" @click="close" />
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
interface ModalChangePasswordProps {}

defineProps<ModalChangePasswordProps>()

const { signIn } = useAuth()
const { user } = useCurrentUser()

const passwordChanged = ref(false)
const formData = ref({
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
})

const heading = computed(() =>
  passwordChanged.value ? 'Password Changed' : 'Change Password',
)

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(
  async (data) => {
    await $post('/api/auth/password/update', data)
    await signIn('credentials', {
      callbackUrl: '/',
      redirect: false,
      type: 'login',
      data: JSON.stringify({
        email: user.value.email,
        password: data.password,
      }),
    })
    passwordChanged.value = true
  },
)
</script>
