export const createPolicyTemplate = async ({
  body,
  params,
}: {
  body: { template: string; title: string }
  params: Parameters<typeof apiPaths.policyTemplates>[0]
}) => {
  const key = getApiResourceKey('policyTemplates', params)
  const path = getApiPath('policyTemplates', params)

  return await createResource<PolicyTemplate>(path, { body, key })
}

export const deletePolicyTemplate = async ({
  body,
  params,
}: {
  body: string
  params: Parameters<typeof apiPaths.policyTemplate>[0]
}) => {
  const key = getApiResourceKey('policyTemplates', params)
  const path = getApiPath('policyTemplate', { ...params, id: body })

  return await deleteResource(path, {
    key,
    id: body,
  })
}

export const savePolicyTemplate = async ({
  body,
  params,
}: {
  body: PolicyTemplate
  params: Parameters<typeof apiPaths.policyTemplate>[0]
}) => {
  const key = getApiResourceKey('policyTemplates', params)
  const path = getApiPath('policyTemplate', { ...params, id: body.id })

  return await updateResource(path, { body, key })
}
