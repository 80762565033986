<template>
  <AtroButton
    icon-size="md"
    icon-right="link"
    text="Copy Invite Link"
    type="flat"
    @click="copy"
  />
</template>

<script setup lang="ts">
const { copy } = useOrgShareUrl()
</script>
