<template>
  <PageController
    :data
    :deps
    :page
    :page-type
    @action="(action: FlowAction, page: Page) => $emit('action', action, page)"
    @submit="(data: any) => $emit('submit', data)"
  >
    <!-- MOBILE SCORE -->
    <template #mainPrefix>
      <AtroContent
        v-if="page.showScore && $isMobile.value"
        class="mb-4 w-full"
        items="center"
        justify="center"
      >
        <FlowPostureCard :score="deps.flow.score" />
      </AtroContent>
    </template>

    <template #asidePrefix>
      <FlowPostureCard v-if="page.showScore" :score="deps.flow.score" />
    </template>
  </PageController>
</template>

<script setup lang="ts">
export interface Props {
  data: Record<string, any>
  deps: {
    currentOrg: Org
    currentUser: User
    flow: Flow
    users: User[]
    parentOrg: ParentOrg
    viewAs: View
  }
  page: Page
  pageType: 'main' | 'modal'

  showScore?: boolean
}

const { data, deps, page } = defineProps<Props>()
defineEmits<{
  action: [action: FlowAction, page: Page]
  submit: [data: any]
}>()

const { overrideWalkthroughSteps } = useOnboarding()
const { valid } = usePageState()

watchEffect(() => {
  if (page.walkthroughSteps) {
    const steps = page.walkthroughSteps.map((step) => {
      const _step = { ...step }
      const _show = _step.show as unknown as ShowConfig
      if (_show) {
        _step.show = computed(() => {
          switch (_show.type) {
            case 'pageValidity':
              return valid.value
            default:
              return false
          }
        })
      }
      return _step
    })
    overrideWalkthroughSteps.value = steps
  }
})

onUnmounted(() => {
  overrideWalkthroughSteps.value = []
})
</script>
