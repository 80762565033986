<template>
  <AtroContent
    v-for="includedItem in includedItems"
    class="mt-2 w-full text-left first:mt-0 sm:mt-3"
    wrap="nowrap"
    :key="includedItem"
  >
    <AtroIcon
      :class="[
        '!border- mt-1 h-4 w-4',
        {
          'text-primary': props.planType !== 'free',
          'text-atro-gray': props.planType === 'free',
        },
      ]"
      name="circle-check"
    />
    <AtroSpan
      class="ml-2 text-atro-dark-purple"
      :size="$isMobile.value ? 'xs' : 'md'"
      :text="includedItem"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  planType: SubscriptionPlanType

  expanded?: boolean
}

const props = defineProps<Props>()

const includedItems = computed(() => {
  switch (props.planType) {
    case 'free':
      return [
        'Device security scanner',
        'Security assessment',
        'Community support',
      ]
    case 'pro':
      if (props.expanded) {
        return [
          'Policies including security, passwords, and more customizable templates',
          'Phishing tests',
          'Security self-assessment',
          'Security awareness training',
          'Cloud services configuration monitoring, including Google Workspace',
          'Customized support from the Atro team',
        ]
      } else {
        return [
          'Policies including security, passwords, and more customizable templates',
          'Phishing tests',
          'Cloud services configuration monitoring, including Google Workspace',
          'Customized support from the Atro team',
        ]
      }
    default:
      return []
  }
})
</script>
