<template>
  <AtroContent
    v-auto-animate
    class="w-full rounded-3xl bg-atro-warm-gray-2 p-6"
    direction="col"
    wrap="nowrap"
  >
    <AtroContent class="mb-4 w-full space-x-10" justify="between">
      <AtroHeading
        semibold
        class="text-atro-slate-purple"
        size="xs"
        text="Notes"
      />
      <AtroButton v-if="!isEditing" type="flat" @click="isEditing = true">
        <AtroIcon class="size-4 text-atro-gray-2" name="pen" />
      </AtroButton>
    </AtroContent>

    <div
      v-if="!isEditing"
      class="flex w-full flex-1 overflow-auto text-left text-atro-gray"
      @click="model === 'Add a note' && (isEditing = true)"
    >
      <AtroMarkdown
        prose
        :class="[{ 'cursor-pointer': model === PLACEHOLDER }]"
        :markdown="model"
      />
    </div>

    <FormKit
      v-else
      v-model="localModel"
      input-class="resize-none"
      type="textarea"
    />

    <AtroContent v-if="isEditing" class="mt-4 w-full space-x-2" justify="end">
      <AtroButton size="sm" text="Cancel" type="light" @click="onCancel" />
      <AtroButton size="sm" text="Save" @click="onSave" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  modelValue?: string
}

const PLACEHOLDER = 'Add a note'

defineProps<Props>()
const emit = defineEmits<{
  save: [value: string]
}>()
const model = defineModel<Props['modelValue']>({
  get: (value) => value || PLACEHOLDER,
})
const localModel = ref(model.value === PLACEHOLDER ? '' : model.value)

const isEditing = ref(false)

function onCancel() {
  localModel.value = model.value === PLACEHOLDER ? '' : model.value
  isEditing.value = false
}

function onSave() {
  model.value = localModel.value
  isEditing.value = false
  emit('save', model.value)
}
</script>
