import { sort } from 'fast-sort'

type SortOptions = {
  dir?: 'asc' | 'desc'
  mode?: 'discover' | 'started'
}

export const sortFlows = (flows: Flow[], options?: SortOptions) => {
  const _options: SortOptions = {
    dir: 'desc',
    mode: 'started',
    ...options,
  }

  let sortOrder: ((flow: Flow) => any)[] = []
  if (_options.mode === 'discover') {
    sortOrder = [
      (flow) => flow.status === 'available',
      (flow) => flow.status === 'coming_soon',
      (flow) => flow.status === 'ok',
      (flow) => flow.status === 'waiting',
      (flow) => flow.status === 'started',
      (flow) => flow.status === 'warning',
      (flow) => flow.weight,
    ]
  } else if (_options.mode === 'started') {
    sortOrder = [
      (flow) => flow.status === 'warning',
      (flow) => flow.status === 'waiting',
      (flow) => flow.status === 'ok',
      (flow) => flow.status === 'started',
      (flow) => flow.status === 'available',
      (flow) => flow.status === 'coming_soon',
      (flow) => flow.weight,
    ]
  }

  // @ts-ignore
  return sort(flows)[_options.dir](sortOrder) as Flow[]
}
