<template>
  <AtroContent class="w-full" direction="col" wrap="nowrap">
    <AtroContent class="space-x-6">
      <AtroContent
        v-for="(tab, i) in tabs"
        tabbable
        :class="[
          'group cursor-pointer space-x-1 border-b-2 pb-2 transition-all focus-visible:border-primary-75',
          selectedTabIndex === i
            ? 'border-primary text-primary'
            : 'border-transparent text-primary-75 hover:border-primary-75',
        ]"
        :key="tab.label"
        items="center"
        justify="center"
        @click="onSelectTab(i)"
        @keyup.space="onSelectTab(i)"
      >
        <NuxtLink class="contents" :to="getTabDeepLink(i)">
          <AtroIcon v-if="tab.icon" class="mr-1 h-3 w-3" :name="tab.icon" />
          <AtroSpan semibold :text="tab.label" />
          <AtroContent
            v-if="tab.count"
            class="h-4 w-4 rounded-full bg-primary/5"
            items="center"
            justify="center"
          >
            <AtroSpan semibold class="leading-3" size="xxs" :text="tab.count" />
          </AtroContent>
        </NuxtLink>
      </AtroContent>
    </AtroContent>
    <AtroContent v-auto-animate class="mt-4 w-full">
      <FlowComponent
        v-for="config in selectedTab.components"
        :config
        :data
        :deps
        :key="`${selectedTab.label}-${config.name}`"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
type Tab = {
  components: FlowComponentConfig[]

  label: string

  count?: string
  icon?: IconName
}

export interface Props {
  tabs: Tab[]

  data?: Record<string, any>
  deps?: Record<string, any>

  deepLink?: boolean
}

const { deepLink, tabs } = defineProps<Props>()

const route = useRoute()

const _selectedTabIndex = ref(0)
const selectedTabIndex = computed({
  get() {
    return deepLink ? Number(route.query?.tab || 0) : _selectedTabIndex.value
  },
  set(val: number) {
    _selectedTabIndex.value = val
  },
})
const selectedTab = computed<Tab>(() => tabs[selectedTabIndex.value])

function onSelectTab(index: number) {
  selectedTabIndex.value = index
}

function getTabDeepLink(index: number) {
  return deepLink
    ? { ...route, query: { ...route.query, tab: index } }
    : undefined
}
</script>
