export default defineNuxtRouteMiddleware((to) => {
  const metaAuth =
    typeof to.meta.auth === 'object'
      ? {
          unauthenticatedOnly: true,
          ...to.meta.auth,
        }
      : to.meta.auth

  if (metaAuth === false) {
    return
  }

  const { status } = useAuth()
  const isGuestMode =
    typeof metaAuth === 'object' && metaAuth.unauthenticatedOnly
  // Guest mode happy path 1: Unauthenticated user is allowed to view page
  if (isGuestMode && status.value === 'unauthenticated') {
    return
  }

  // Guest mode edge-case: Developer used guest-mode config style but set `unauthenticatedOnly` to `false`
  if (typeof metaAuth === 'object' && !metaAuth.unauthenticatedOnly) {
    return
  }

  if (status.value === 'authenticated') {
    // Guest mode happy path 2: Authenticated user should be directed to another page
    if (isGuestMode) {
      return navigateTo(metaAuth.navigateAuthenticatedTo ?? '/')
    }
    return
  }

  const matchedRoute = to.matched.length > 0
  if (!matchedRoute) {
    // Hands control back to `vue-router`, which will direct to the `404` page
    return
  }

  return navigateTo(
    {
      path: '/login',
      query: { ...to.query, ...(to.path !== '/' ? { next: to.path } : {}) },
    },
    { replace: true },
  )
})
