export function useOrgShareUrl() {
  const { org } = useCurrentOrg()
  const copyText = useCopyText()

  const shareUrl = computed(
    () =>
      `${window.location.origin}/signup/?invite-token=${org.value?.inviteToken}`,
  )

  function _copy() {
    copyText(shareUrl.value, 'Link copied!')
  }

  return { copy: _copy }
}
