<template>
  <AtroContent class="w-full p-4 !pt-0 sm:p-6">
    <AtroContent v-auto-animate class="w-full gap-2">
      <ActionCenterItem
        v-for="action in actionsToShow"
        :action
        :view-as
        :key="action.title"
      />
    </AtroContent>
    <!-- EMPTY -->
    <AtroEmpty v-if="hasNoActiveActions && !showAllActions">
      <AtroIconWrap circle class="text-atro-green" name="check" type="dark" />
      <AtroHeading
        semibold
        class="text-atro-gray"
        size="sm"
        text="Your all caught up"
      />
    </AtroEmpty>

    <!-- SHOW ALL/MORE -->
    <AtroContent
      v-if="showExpandToggle"
      class="mt-4 w-full"
      items="center"
      justify="center"
    >
      <AtroButton
        class="text-sm !font-normal !text-atro-gray-2"
        type="flat"
        :icon-right="showAllActions ? 'chevron-up' : 'chevron-down'"
        :text="showAllButtonText"
        @click="showAllActions = !showAllActions"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  actions: Action[]
  viewAs: View

  showAll?: boolean
}

const ROLLUP_COUNT = 3

const { actions, showAll, viewAs } = defineProps<Props>()

const { activeActions, hasInactiveActions, hasNoActiveActions } =
  useActions(actions)
const showAllActions = ref(showAll)

const actionsToShow = computed(() => {
  return showAllActions.value
    ? actions
    : viewAs === 'admin'
      ? activeActions.value.slice(0, ROLLUP_COUNT)
      : activeActions.value
})

const showAllButtonText = computed(() => {
  if (viewAs === 'admin') {
    return showAllActions.value ? 'Collapse' : 'Show All'
  } else {
    return showAllActions.value ? 'Hide Past Actions' : 'See History'
  }
})

const showExpandToggle = computed(() =>
  viewAs === 'admin'
    ? activeActions.value > actionsToShow.value || showAllActions.value
    : hasInactiveActions.value,
)
</script>
