export default defineNuxtRouteMiddleware((to) => {
  const { org } = useCurrentOrg()
  const { isPartner } = useCurrentUser()

  if (!isPartner.value && org.value?.kioskOrgFlowId) {
    if (!to.path.includes(org.value.kioskOrgFlowId)) {
      console.log('KIOSK MODE REDIRECT')
      return navigateToWithOrg(`/org-flows/${org.value.kioskOrgFlowId}`)
    }
  }
})
