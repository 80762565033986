export function wrapPathWithOrg(path: string) {
  const { rootPath } = useCurrentOrg()

  return `${rootPath.value}${path}`
}

export function wrapPathWithParentOrg(path: string) {
  const { parentOrg } = useUserOrgs()

  return parentOrg.value?.slug ? `/orgs/${parentOrg.value?.slug}${path}` : ''
}

export function navigateToWithOrg(...args: Parameters<typeof navigateTo>) {
  if (typeof args[0] === 'string') {
    args[0] = wrapPathWithOrg(args[0])
  } else {
    // @ts-ignore
    args[0].path = wrapPathWithOrg(args[0].path as string)
  }

  return navigateTo(...args)
}
