<template>
  <AtroContent
    class="w-full flex-1 sm:min-w-[400px]"
    direction="col"
    wrap="nowrap"
  >
    <AtroContent class="w-full px-4" direction="col" wrap="nowrap">
      <AtroContent
        v-if="canImport"
        class="mb-10 w-full"
        items="center"
        justify="center"
      >
        <FormKit
          v-model="enableImport"
          prefix-icon="sparkles"
          inner-class="!text-atro-gray"
          type="switch"
          text="Create Atro users"
          text-position="left"
          :text-semibold="true"
        />
        <InfoTooltip
          class="ml-3"
          placement="top-start"
          :skidding="-40"
          :tooltip="{
            icon: 'sparkles',
            title: 'Auto-assign',
            text: 'We’ll automatically assign this to any future users added to Atro',
          }"
        />
      </AtroContent>

      <AtroContent class="mb-6 w-full" wrap="nowrap">
        <FormKit
          type="search"
          placeholder="Search team members"
          prefix-icon="search"
          v-model="searchTerm"
        />
      </AtroContent>

      <AtroContent
        class="w-full pr-2.5 text-atro-gray"
        justify="between"
        wrap="nowrap"
      >
        <AtroSpan text="Your Groups" />
        <AtroSwitch
          label="Include all"
          label-position="left"
          v-model="allIncluded"
        />
      </AtroContent>
    </AtroContent>

    <AtroContent
      v-auto-animate
      class="mt-4 w-full flex-1 space-y-2"
      direction="col"
      wrap="nowrap"
    >
      <UserIncludedGroupItem
        v-for="group in _groups"
        :group
        :key="group.id"
        v-model="excludedUserIds"
      />
      <AtroEmpty
        v-if="!_groups.length && searchTerm"
        :body="`No users found containing: ${searchTerm}`"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: {
    enableImport: boolean
    excludedUserIds: string[]
    excludedGroupIds: string[]
  }

  canImport?: boolean
  groups?: Group[]
  required?: boolean
}

const { required, canImport = true, groups = [] } = defineProps<Props>()
const model = defineModel<Props['modelValue']>({ default: {} })
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

const searchTerm = ref('')

const allIncluded = computed({
  get() {
    return !excludedUserIds.value.length
  },
  set(value: boolean) {
    if (value) assignAll()
    else unassignAll()
  },
})
const allUsers = computed(() =>
  groups.reduce((userIds, group) => {
    userIds = userIds.concat(group.users.map((user) => user.id))
    return userIds
  }, [] as string[]),
)

const _groups = computed(() => {
  const normalizedSearchTerm = searchTerm.value?.toLocaleLowerCase() || ''
  return groups.reduce((g, group) => {
    const copy = { ...group }
    copy.users = copy.users.filter(
      (user) =>
        group.name.toLocaleLowerCase().includes(normalizedSearchTerm) ||
        user.displayName?.toLocaleLowerCase().includes(normalizedSearchTerm) ||
        user.firstName?.toLocaleLowerCase().includes(normalizedSearchTerm) ||
        user.lastName?.toLocaleLowerCase().includes(normalizedSearchTerm) ||
        user.email?.toLocaleLowerCase().includes(normalizedSearchTerm),
    )
    if (copy.users.length) {
      g.push(copy)
    }
    return g
  }, [] as Group[])
})

const excludedUserIds = computed({
  get() {
    const userIds = model.value?.excludedUserIds
    return userIds || []
  },
  set(value) {
    const excludedGroupIds = groups.reduce((ids, group) => {
      const everyUserExcluded = group.users.every((user) =>
        value.includes(user.id),
      )
      if (everyUserExcluded) {
        ids.push(group.id)
      }
      return ids
    }, [] as string[])
    if (required)
      emit(
        'validityChange',
        excludedUserIds.value.length !== allUsers.value.length,
      )
    model.value = {
      ...model.value,
      excludedGroupIds,
      excludedUserIds: value,
    }
  },
})

const enableImport = computed({
  get() {
    return (
      typeof model.value?.enableImport === 'undefined' ||
      model.value?.enableImport
    )
  },
  set(value) {
    model.value = {
      ...model.value,
      enableImport: value,
    }
  },
})

function assignAll() {
  excludedUserIds.value = []
}

function unassignAll() {
  excludedUserIds.value = allUsers.value
}
</script>
