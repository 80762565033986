<template>
  <AtroGridTableRow>
    <!-- MEMBER NAME -->
    <AtroGridTableCell>
      <UserDisplay
        class="w-full"
        default-slot-direction="row"
        size="sm"
        :user="userAwarenessReport.user"
      />
    </AtroGridTableCell>

    <!-- CURRENT STATUS -->
    <AtroGridTableCell justify="center">
      <AtroBadge class="w-full" :label="statusText" :type="statusBadgeType" />
    </AtroGridTableCell>

    <!-- SCORE -->
    <AtroGridTableCell justify="center">
      <ScoreGridTableCell size="sm" :score="userAwarenessReport.score" />
    </AtroGridTableCell>

    <!-- LAST TRAINED AT -->
    <AtroGridTableCell class="relative" justify="center">
      <AtroSpan
        semibold
        :class="[
          'transition-opacity group-hover:opacity-0',
          {
            'text-atro-gray': lastTrainedAt !== '—',
            'text-atro-warm-gray': lastTrainedAt === '—',
          },
        ]"
        size="xs"
        :text="lastTrainedAt"
      />
      <AtroButton
        class="!absolute opacity-0 group-hover:opacity-100"
        size="sm"
        text="Train Now"
        type="flat"
        @click="$emit('retrain', userAwarenessReport.user.id)"
      />
    </AtroGridTableCell>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { BadgeProps } from '@atro/components'

export interface Props {
  userAwarenessReport: UserAwarenessReport
}

const { userAwarenessReport } = defineProps<Props>()
defineEmits<{
  retrain: [userId: string]
}>()

const lastTrainedAt = computed(() => {
  if (userAwarenessReport.lastTrainedAt) {
    const date = new Date(userAwarenessReport.lastTrainedAt)
    return format(date, 'MM.dd.yyyy')
  } else {
    return '—'
  }
})

const statusText = computed(() => {
  switch (userAwarenessReport.status) {
    case 'high-risk':
      return 'High Risk'
    case 'overdue':
      return 'Overdue'
    case 'trained':
      return 'Trained'
    case 'waiting':
      return 'Assigned'
    default:
      return ''
  }
})
const statusBadgeType = computed<BadgeProps['type']>(() => {
  switch (userAwarenessReport.status) {
    case 'high-risk':
      return 'incorrect'
    case 'overdue':
      return 'incorrect'
    case 'trained':
      return 'correct'
    case 'waiting':
      return 'primary'
    default:
      return 'neutral'
  }
})
</script>
