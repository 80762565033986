export default defineNuxtRouteMiddleware((to, from) => {
  const { clearDirty, pageIsDirty } = usePageDirtyTracking(false)
  const onDirtyConfirm = useState('onDirtyConfirm')

  if (from.meta.blockNavigationOnDirty && pageIsDirty.value) {
    onDirtyConfirm.value = function () {
      onDirtyConfirm.value = null
      clearDirty()
      navigateTo(to)
      return true
    }
    return abortNavigation()
  } else {
    clearDirty()
  }
})
