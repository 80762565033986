import type { RouteLocationNormalizedLoaded } from '#vue-router'

export function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function isObject(obj: any) {
  return (obj ?? false)?.constructor?.name === 'Object'
}

export function removeQueryParam(
  route: RouteLocationNormalizedLoaded,
  paramName: string | string[],
) {
  const queryParams = { ...route.query }
  if (Array.isArray(paramName)) {
    paramName.forEach((param) => delete queryParams[param])
  } else {
    delete queryParams[paramName]
  }
  return navigateTo({ query: queryParams }, { replace: true })
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export function deepToRaw<T extends Record<string, any>>(sourceObj: T): T {
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item))
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input))
    }
    if (input && typeof input === 'object') {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key])
        return acc
      }, {} as T)
    }
    return input
  }

  return objectIterator(sourceObj)
}

export function saveAs(uri: string, filename: string) {
  const link = document.createElement('a')

  if (typeof link.download === 'string') {
    link.href = uri
    link.download = filename

    // Firefox requires the link to be in the body
    document.body.appendChild(link)

    // simulate click
    link.click()

    // remove the link when done
    document.body.removeChild(link)
  } else {
    window.open(uri)
  }
}
