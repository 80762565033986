<template>
  <AtroContent
    class="w-full rounded-3xl bg-atro-warm-gray-2 p-6"
    direction="col"
  >
    <!-- QUESTION HEADING -->
    <AtroContent class="w-full" items="center" justify="between">
      <AtroHeading
        semibold
        class="text-atro-slate-purple"
        size="sm"
        :text="`Question ${index + 1}`"
      />
      <AtroButton
        type="flat"
        :disabled="!canRemove"
        @click="$emit('remove', index)"
      >
        <AtroIcon class="size-4" name="trash" />
      </AtroButton>
    </AtroContent>

    <!-- QUESTION BODY -->
    <AtroContent class="mt-6 w-full space-y-6" direction="col">
      <Form v-model="model" :id input-width="full">
        <!-- QUESTION INPUT -->
        <FormKit
          type="text"
          name="question"
          placeholder="Enter your question"
          validation="required"
        >
          <template #label>
            <AtroContent class="mb-3" items="center">
              <AtroIcon
                class="mr-2 size-4 text-atro-gray"
                name="circle-question"
              />
              <AtroSpan
                semibold
                class="text-atro-gray"
                size="sm"
                text="Question"
              />
              <span class="field-is-required">*</span>
            </AtroContent>
          </template>
        </FormKit>
        <!-- CORRECT ANSWER INPUT -->
        <FormKit
          type="text"
          name="correctAnswer"
          placeholder="Enter the correct answer"
          validation="required"
        >
          <template #label>
            <AtroContent class="mb-3" items="center">
              <AtroIcon
                class="mr-2 size-4 text-atro-green"
                name="circle-check"
              />
              <AtroSpan
                semibold
                class="text-atro-gray"
                size="sm"
                text="Correct Answer"
              />
              <span class="field-is-required">*</span>
            </AtroContent>
          </template>
        </FormKit>
        <FormKit name="incorrectAnswers" type="list">
          <!-- INCORRECT (REQUIRED) ANSWER INPUT -->
          <FormKit
            type="text"
            placeholder="Enter an incorrect answer"
            validation="required"
          >
            <template #label>
              <AtroContent class="mb-3" items="center">
                <AtroIcon
                  class="mr-2 size-4 text-atro-red"
                  name="circle-xmark"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="Incorrect Answer"
                />
                <span class="field-is-required">*</span>
              </AtroContent>
            </template>
          </FormKit>
          <!-- INCORRECT (OPTIONAL) ANSWER INPUT -->
          <FormKit
            type="text"
            placeholder="Enter an additional incorrect answer"
          >
            <template #label>
              <AtroContent class="mb-3" items="center">
                <AtroIcon
                  class="mr-2 size-4 text-atro-red"
                  name="circle-xmark"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="Incorrect Answer (optional)"
                />
              </AtroContent>
            </template>
          </FormKit>
          <!-- INCORRECT (OPTIONAL) ANSWER INPUT -->
          <FormKit
            type="text"
            label="Incorrect Answer (optional)"
            placeholder="Enter an additional incorrect answer"
          >
            <template #label>
              <AtroContent class="mb-3" items="center">
                <AtroIcon
                  class="mr-2 size-4 text-atro-red"
                  name="circle-xmark"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="Incorrect Answer (optional)"
                />
              </AtroContent>
            </template>
          </FormKit>
        </FormKit>
      </Form>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { FormKitNode, FormKitFrameworkContext } from '@formkit/core'
import { getNode } from '@formkit/core'

export interface Props {
  canRemove: boolean
  index: number
  modelValue: QuizQuestion
}

const props = defineProps<Props>()
const model = defineModel<Props['modelValue']>()
const emit = defineEmits<{
  remove: [index: number]
  validityChange: [valid: boolean]
}>()

const id = useId()
const validity = ref()

onMounted(() => {
  const form = getNode(id) as FormKitNode

  watch(
    form.context as FormKitFrameworkContext,
    () => {
      if (validity.value !== !!form.context?.state.valid) {
        validity.value = !!form.context?.state.valid
        emit('validityChange', validity.value)
      }
    },
    { immediate: true },
  )
})
</script>
