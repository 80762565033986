<template>
  <AtroPopover
    placement="right"
    floating-class="!z-max"
    strategy="fixed"
    v-bind="$attrs"
  >
    <template #trigger><slot /></template>
    <template #content>
      <AtroContent
        class="max-w-xs space-y-4 sm:max-w-[409px]"
        direction="col"
        items="center"
      >
        <AtroContent class="w-full space-x-2" items="center" wrap="nowrap">
          <AtroIcon class="size-6" :name="flow.icon" />
          <AtroSpan
            semibold
            class="text-atro-slate-purple"
            size="sm"
            :text="flow.title"
          />
        </AtroContent>

        <AtroSpan
          v-if="flowNeedsAssignment && user"
          semibold
          class="text-atro-gray"
          size="xs"
          :text="`Click to assign ${user.displayName || user.email}`"
        />

        <!-- CHECKS -->
        <AtroContent
          v-for="check in userFlowReport?.checks"
          :class="[
            'w-full space-x-2',
            {
              'text-atro-slate-purple': !check.status,
              'text-atro-green': check.status === 'ok',
              'text-atro-blue': check.status === 'waiting',
              'text-atro-red': check.status === 'warn',
            },
          ]"
          wrap="nowrap"
          :key="check.id"
        >
          <AtroIcon
            class="size-6"
            :name="
              check.status === 'ok'
                ? 'circle-check'
                : check.status === 'warn'
                  ? 'circle-exclamation'
                  : 'circle-rotate'
            "
          />
          <AtroMarkdown class="text-sm" :markdown="check.title" />
        </AtroContent>
      </AtroContent>
    </template>
  </AtroPopover>
</template>

<script setup lang="ts">
export interface Props {
  flow: Flow

  user?: User
  userFlowReport?: UserFlowReport
}

const { flow, userFlowReport } = defineProps<Props>()

const flowNeedsAssignment = computed(
  () => !hasUserFlowReport.value && flow?.isAssignable,
)
const hasUserFlowReport = computed(() => userFlowReport !== undefined)
</script>
