import { mergeAttributes, Node } from '@tiptap/core'

export interface SmartSpanOptions {
  HTMLAttributes: Record<string, any>
}

export const SmartSpan = Node.create<SmartSpanOptions>({
  name: 'smartSpan',
  group: 'inline',
  inline: true,
  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => {
          const hasDataSmart = (element as HTMLElement).hasAttribute(
            'data-smart',
          )

          if (!hasDataSmart) {
            return false
          }

          return {}
        },
      },
    ]
  },

  addAttributes() {
    return {
      'data-content': {
        parseHTML: (element) => element.innerHTML,
      },
      'data-focused': {
        parseHTML: (element) => element.getAttribute('data-focused'),
      },
      'data-initial': {
        parseHTML: (element) => element.getAttribute('data-initial'),
      },
      'data-smart': {
        parseHTML: (element) => element.getAttribute('data-smart'),
      },
      'data-type': {
        parseHTML: (element) => element.getAttribute('data-type'),
      },
      'data-props': {
        parseHTML: (element) => element.getAttribute('data-props'),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      HTMLAttributes['data-content'] || HTMLAttributes['data-initial'],
    ]
  },
})
