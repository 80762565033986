export function getUserReportAlertCount(userReport: UserReport) {
  return userReport.userOrgFlowReports?.reduce((count, report) => {
    count += getUserFlowReportAlertCount(report)
    return count
  }, 0)
}

export function getUserFlowReportAlertCount(userFlowReport: UserFlowReport) {
  return userFlowReport.checks?.reduce((statusCount, check) => {
    statusCount += check.status === 'warn' ? 1 : 0
    return statusCount
  }, 0) || userFlowReport.status === 'warning'
    ? 1
    : 0
}
