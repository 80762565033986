<template>
  <AtroContent class="w-full px-3 sm:p-0" direction="col">
    <div class="relative mb-2 flex flex-col items-center" ref="riskIndicator">
      <AtroHeading
        semibold
        class="mb-1 capitalize text-atro-dark-purple"
        size="xs"
        >{{ riskLevelText }}</AtroHeading
      >
      <AtroIcon
        class="h-4 w-4 rotate-180 text-atro-dark-purple"
        name="triangle"
      />
    </div>

    <div
      class="relative grid h-6 w-full grid-cols-3 overflow-hidden rounded-full"
    >
      <div class="h-full w-full bg-atro-red" />
      <div class="h-full w-full bg-atro-yellow" />
      <div class="h-full w-full bg-atro-green" />
    </div>

    <div class="mt-4 grid w-full grid-cols-3 text-center">
      <AtroSpan semibold class="text-atro-gray" size="xs" text="Critical" />
      <AtroSpan semibold class="text-atro-gray" size="xs" text="Insecure" />
      <AtroSpan semibold class="text-atro-gray" size="xs" text="Secure" />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
import gsap from 'gsap'

export interface Props {
  riskLevel: number
}

const { riskLevel } = defineProps<Props>()

const riskIndicator = ref(null)

const duration = computed(() => {
  if (riskLevel < 0.33) {
    return 0.6
  } else if (riskLevel < 0.66) {
    return 0.8
  } else {
    return 1
  }
})

const invertedRiskLevel = computed(() => 1 - riskLevel)
const pointerAdjustedLeft = computed(() => {
  let _riskLevel = Math.max(0.05, invertedRiskLevel.value)
  _riskLevel = Math.min(0.95, _riskLevel)

  return `${_riskLevel * 100}%`
})

const riskLevelText = computed(() => {
  if (riskLevel < 0.33) {
    return 'Secure'
  } else if (riskLevel < 0.66) {
    return 'Insecure'
  } else {
    return 'Critical'
  }
})

onMounted(() => {
  gsap.to(riskIndicator.value, {
    left: pointerAdjustedLeft.value,
    translateX: '-50%',
    duration: duration.value,
    delay: 0.3,
    ease: 'ease-in-out',
  })
})
</script>
