import type { Props as FlowCardTransitionalProps } from '@/components/flow/FlowCardTransitional.vue'

export default function useFlowCardTransition(removeWhenCompleted?: boolean) {
  const { saveWindowScroll } = useWindowScrollState()

  const open = useState('flowCardOpen', () => false)
  const status = useState<'closing' | 'idle' | 'opening'>(
    'flowCardTransitionStatus',
    () => 'idle',
  )
  const transitionCompleted = useState(
    'flowCardTransitionalTransitionCompleted',
    () => false,
  )
  const prevFlowCardTransitionConfig =
    useState<FlowCardTransitionalProps | null>('prevFlowCardTransitionConfig')
  const flowCardTransitionConfig = useState<FlowCardTransitionalProps | null>(
    'flowCardTransitionConfig',
  )
  const isAnimating = computed(() => status.value !== 'idle')

  function clearTransitionCard() {
    flowCardTransitionConfig.value = null
    transitionCompleted.value = false
    status.value = 'idle'
  }

  function startCloseTransition() {
    if (prevFlowCardTransitionConfig.value) {
      flowCardTransitionConfig.value = {
        ...prevFlowCardTransitionConfig.value,
        view: 'close',
      }
      status.value = 'closing'
    }
  }

  function startOpenTransition(
    flow: Flow,
    cardRect: DOMRect,
    bottomToolbarActive?: boolean,
  ) {
    saveWindowScroll()
    flowCardTransitionConfig.value = {
      cardRect,
      flow,
      bottomToolbarActive: bottomToolbarActive || false,
      view: 'open',
    }
    status.value = 'opening'
  }

  function endTransition() {
    if (status.value !== 'idle') {
      transitionCompleted.value = true
      prevFlowCardTransitionConfig.value = flowCardTransitionConfig.value
      open.value = status.value === 'opening'
      status.value = 'idle'
    }
  }

  watchEffect(async () => {
    if (removeWhenCompleted && transitionCompleted.value) {
      await nextTick()
      clearTransitionCard()
    }
  })

  return {
    isAnimating,
    flowCardTransitionConfig,
    open,
    status,
    transitionCompleted,
    clearTransitionCard,
    startCloseTransition,
    startOpenTransition,
    endTransition,
  }
}
