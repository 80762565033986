<template>
  <template v-if="$isMobile.value">
    <div>
      <AtroButton
        type="flat"
        :class="menuButtonClass"
        @click.stop.prevent="isMobileOverflowMenuOpen = true"
      >
        <AtroIcon
          :class="['h-6 w-6', iconClass, { 'rotate-90': vertical }]"
          name="ellipsis"
        />
      </AtroButton>

      <AtroModal
        size="sm"
        type="controls"
        :closeable="false"
        :is-open="isMobileOverflowMenuOpen"
        :is-mobile="$isMobile.value"
        @close="isMobileOverflowMenuOpen = false"
      >
        <AtroContent class="w-full space-y-4" direction="col">
          <div
            v-for="item in items"
            as="div"
            :key="item.label"
            @click="() => onMenuItemClick(item)"
          >
            <button v-if="item.action" class="">
              <AtroMenuItem :item :is-mobile="$isMobile.value" />
            </button>

            <NuxtLink v-if="item.linkTo" :to="item.linkTo">
              <AtroMenuItem :item :is-mobile="$isMobile.value" />
            </NuxtLink>
          </div>
        </AtroContent>

        <template #actions="{ close }">
          <AtroButton block text="Close" @click="close" />
        </template>
      </AtroModal>
    </div>
  </template>
  <AtroMenu
    v-else
    :items
    menu-item-class="text-primary hover:text-primary-75"
    strategy="fixed"
    :class="attrs.class"
    :menu-class="`overflow-menu z-max ${menuClass}`"
    @action="(action: string) => emit('action', action)"
  >
    <template #menuButton="{ open }">
      <AtroContent
        items="center"
        justify="center"
        :class="[
          'rounded-lg px-2 py-2 text-primary transition-colors',
          menuButtonClass,
          open
            ? 'bg-atro-warm-gray'
            : 'hover:bg-atro-warm-gray-2 group-focus-visible:bg-atro-warm-gray-2',
          {
            'h-6 w-6': size === 'xs',
            'h-8 w-8': size === 'sm',
            'h-10 w-10': size === 'md',
          },
        ]"
      >
        <AtroIcon
          name="ellipsis"
          :class="['h-full w-full', iconClass, { 'rotate-90': vertical }]"
        />
      </AtroContent>
    </template>
  </AtroMenu>
</template>

<script setup lang="ts">
export interface Props {
  items: MenuItem[]

  iconClass?: string
  menuClass?: string
  menuButtonClass?: string
  size?: 'xs' | 'sm' | 'md'
  vertical?: boolean
}

const { size = 'md' } = defineProps<Props>()

const emit = defineEmits<{
  action: [action: any]
}>()
const attrs = useAttrs()

const isMobileOverflowMenuOpen = ref(false)

function onMenuItemClick(item: MenuItem) {
  if (item.action) emit('action', item.action)
  else if (item.linkTo) navigateTo(item.linkTo)
  isMobileOverflowMenuOpen.value = false
}
</script>
