import type { PageMeta } from '#app'

export default function useLayoutConfig() {
  const router = useRouter()
  const config = computed(
    () =>
      (router.currentRoute.value.meta.layoutConfig ||
        {}) as PageMeta['layoutConfig'],
  )

  return {
    layoutConfig: config,
    layoutView: computed<LayoutView>(() => config.value?.view || 'default'),
  }
}
