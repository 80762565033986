<template>
  <AtroModal size="md">
    <AtroModalHeader :subtext="action.subtitle" :text="action.title">
      <template v-if="action.points" #above>
        <ScorePoints size="md" :points="action.points" />
      </template>
    </AtroModalHeader>

    <AtroModalContent class="mt-4 space-y-6">
      <AtroContent
        v-if="action.directions"
        class="w-full space-y-2"
        items="center"
        justify="center"
      >
        <AtroSpan semibold class="text-atro-slate-purple" text="Directions" />
        <AtroParagraph class="text-atro-gray" :text="action.directions" />
      </AtroContent>

      <AtroModalActions>
        <AtroButton
          :pending
          :icon-right="action.actionIcon || 'check'"
          :text="action.actionLabel || 'I fixed it'"
          @click="onPrimaryAction"
          @keydown.space.prevent="onPrimaryAction"
        />
        <AtroButton
          icon-right="snooze"
          text="Ignore this Alert"
          type="transparent"
          :pending="pendingSecondary"
          @click="onSecondaryAction"
          @keydown.space.prevent="onSecondaryAction"
        />
      </AtroModalActions>
    </AtroModalContent>
  </AtroModal>
</template>

<script setup lang="ts">
export interface ModalActionProps {
  action: Action
}

const { action } = defineProps<ModalActionProps>()
const { pending, wrapWithPending } = usePendingWrap()
const { pending: pendingSecondary, wrapWithPending: wrapWithSecondaryPending } =
  usePendingWrap()

function onPrimaryAction() {
  console.log('Primary click', action.actionType)
  switch (action.actionType) {
    case '':
      break
  }
}

function onSecondaryAction() {
  // mark action as ignored
  console.log('secondary action click')
}
</script>
