<template>
  <AtroModal size="sm" type="controls" :closeable="false">
    <AtroContent class="w-full space-y-4" direction="col">
      <FlowComponent
        v-for="config in components"
        :config
        :data
        :deps
        :key="config.name"
        @action="onAction"
        @validity-change="onValidityChange"
      />
    </AtroContent>

    <template #actions="{ close }">
      <AtroButton block text="Close" @click="close" />
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
export interface ModalMobileFlowAsideProps {
  components: FlowComponentConfig[]

  data?: Record<string, any>
  deps?: Record<string, any>
}

defineProps<ModalMobileFlowAsideProps>()

const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()

function onAction(action: FlowAction) {
  emit('action', action)
}

function onValidityChange(valid: boolean) {
  emit('validityChange', valid)
}
</script>
