export default function useDownloadHtmlToPDF() {
  function download(dataUrl: string, filename: string) {
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = filename
    link.click()
  }

  async function downloadHtmlToPDF(name: string, el: HTMLElement) {
    const { toPng } = await import('html-to-image')
    const img = await toPng(el)
    download(img, name)
  }

  return downloadHtmlToPDF
}
