<template>
  <ModalConfirm
    :is-open
    :on-cancel
    heading-text="Warning: You will lose data!"
    body-text="Any edits or changes you or other admins have previously made will be lost, proceed with caution."
    cancel-action-text="Nevermind"
    :on-confirm="onDirtyConfirm"
  />
</template>

<script setup lang="ts">
const onDirtyConfirm = useState<(() => boolean) | undefined>('onDirtyConfirm')

const isOpen = computed(() => !!onDirtyConfirm.value)

function onCancel() {
  onDirtyConfirm.value = undefined
}

onUnmounted(onCancel)
</script>
