export default defineNuxtRouteMiddleware((to) => {
  const { orgs } = useUserOrgs()

  if (
    to.name?.toString().includes('orgs-slug') &&
    !orgs.value?.find((_org) => _org.slug === to.params.slug)
  ) {
    return navigateTo('/')
  }
})
