<template>
  <AtroGridTable gap="sm" :columns="columnsConfig" :data="userAwarenessReports">
    <template #default="{ columnProps, items }">
      <UserAwarnessReportTableRow
        v-for="userAwarenessReport in items"
        v-bind="columnProps"
        :user-awareness-report
        :key="userAwarenessReport.user.id"
        @retrain="(userId: string) => $emit('retrain', userId)"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
export interface Props {
  userAwarenessReports: UserAwarenessReport[]
}

defineProps<Props>()
defineEmits<{
  retrain: [userId: string]
}>()

const columnsConfig = computed<TableColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Assigned Users',
    },
    minColumnWidth: 180,
    sortBy: ['user.displayName', 'user.email'],
  },
  {
    heading: {
      align: 'center',
      text: 'Current Status',
    },
    fixedWidth: true,
    minColumnWidth: 110,
    sortBy: 'status',
    initialSortedBy: 'asc',
  },
  {
    heading: {
      align: 'center',
      text: 'Score',
    },
    minColumnWidth: 112,
    sortBy: 'score',
  },
  {
    heading: {
      align: 'center',
      text: 'Last Trained',
    },
    minColumnWidth: 74,
  },
])
</script>
