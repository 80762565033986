type GlobalState = {
  appBannerShown?: boolean
  bottomToolbarActive?: boolean
  flowCompleted?: boolean
  isLoading?: boolean
  logrocketSessionUrl?: string
  modalStack: ModalState[]
  onboardingWidgetConfetti?: boolean
  showOnboardingWidget?: boolean
  upgradeModalShown?: boolean
}

export default function useGlobalState() {
  const globalState = useState(
    'globalState',
    () => ({ modalStack: [] }) as GlobalState,
  )
  return globalState
}
