export function isEmail(value: string) {
  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return emailRegex.test(value)
}

export function isEmails(value: string) {
  const emailRegex =
    /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,\s*)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/i
  return emailRegex.test(value)
}
