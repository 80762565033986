<template>
  <AtroContent
    direction="col"
    :class="[
      'min-w-80 flex-1 cursor-pointer overflow-hidden rounded-3xl border transition-colors hover:border-primary',
      { 'border-primary': selected },
    ]"
  >
    <!-- PREFERRED HEADING -->
    <AtroContent
      :class="['h-[36px] w-full space-x-2 px-4', { 'bg-primary/5': preferred }]"
      items="center"
    >
      <template v-if="preferred">
        <AtroIcon class="size-4 text-primary" name="star" />
        <AtroSpan semibold class="text-atro-gray-2" text="Preffered Partner" />
      </template>
    </AtroContent>

    <AtroContent
      class="w-full flex-1 p-8"
      justify="between"
      direction="col"
      wrap="nowrap"
    >
      <!-- INFO -->
      <AtroContent class="mb-16 w-full" direction="col" wrap="nowrap">
        <AtroIcon class="h-10 w-full max-w-36" :name="icon" />
        <AtroSpan class="mt-6 text-atro-gray-2" size="lg" :text="title" />
      </AtroContent>

      <!-- PRICING -->
      <AtroContent v-if="atroPricing" class="w-full space-x-5" wrap="nowrap">
        <!-- ATRO PRICING -->
        <AtroContent class="space-y-3" direction="col">
          <div
            :class="[
              'w-full rounded bg-atro-green px-[3px] text-center',
              { 'py-1.5': freeWithAtroPro, 'py-1': !freeWithAtroPro },
            ]"
          >
            <AtroSpan
              semibold
              class="px-2 leading-none text-white"
              size="xs"
              :text="freeWithAtroPro ? 'Included with Atro' : 'Atro price'"
            />
          </div>
          <AtroContent v-if="!freeWithAtroPro" direction="col">
            <div class="space-x-0.5 text-atro-green">
              <AtroSpan semibold size="xl" :text="atroPricing.price" />
              <AtroSpan
                v-if="atroPricing.quanta"
                semibold
                size="sm"
                :text="atroPricing.quanta"
              />
            </div>
            <AtroSpan
              class="text-atro-gray"
              size="xs"
              :text="
                atroPricing.period === 'annual'
                  ? '*Billed annually'
                  : '*Billed monthly'
              "
            />
          </AtroContent>
        </AtroContent>
        <!-- FULL PRICING -->
        <AtroContent v-if="fullPricing" class="mt-[22px]" direction="col">
          <AtroSpan
            semibold
            class="text-atro-gray line-through"
            text="Full price"
          />
          <div class="space-x-0.5 text-atro-red">
            <AtroSpan semibold class="line-through" :text="fullPricing.price" />
            <AtroSpan
              v-if="fullPricing.quanta"
              semibold
              class="line-through"
              size="xs"
              :text="fullPricing.quanta"
            />
          </div>
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
type Pricing = {
  price: string
  period: 'monthly' | 'annual'

  quanta?: string
}

export interface Props {
  icon: IconName
  slug: string
  title: string

  atroPricing?: Pricing
  fullPricing?: Pricing
  preferred?: boolean
  selected?: boolean
}

const props = defineProps<Props>()

const freeWithAtroPro = computed(
  () => typeof props.atroPricing !== 'undefined' && !props.atroPricing.price,
)
</script>
