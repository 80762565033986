<template>
  <AtroContent class="w-full" direction="col">
    <AtroContent class="w-full space-x-4" wrap="nowrap">
      <AtroIconWrap
        v-if="typeof data !== 'string' && data.iconLeft"
        size="sm"
        :name="data.iconLeft"
      />
      <AtroContent
        shrink
        class="w-full space-y-4"
        direction="col"
        wrap="nowrap"
      >
        <AtroMarkdown
          prose
          :markdown="typeof data === 'string' ? data : data.text"
          @action="(action: FlowAction) => emit('action', action)"
        />
        <AtroButton
          v-if="actionLabel"
          icon-right="chevron-right"
          type="flat"
          :text="actionLabel"
          @click="actionLabel && emit('action', action)"
        />
      </AtroContent>
      <AtroIconWrap
        v-if="typeof data !== 'string' && data.iconRight"
        size="sm"
        :name="data.iconRight"
      />
      <FlowAtomBadge v-if="badge" v-bind="badge" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  data:
    | string
    | {
        text: string
        iconLeft?: IconName
        iconRight?: IconName
      }

  action?: FlowAction
  actionLabel?: string
  badge?: FlowAtomBadge
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action?: FlowAction]
}>()
</script>
