<template>
  <AtroModal>
    <AtroModalHeader text="Edit Organization" />

    <Form v-model="model" input-width="full" @submit="onSubmit">
      <FormKit name="name" type="text" label="Name" validation="required" />
      <FormKit name="slug" type="text" label="Slug" validation="required" />

      <template #submit="{ state: { valid } }">
        <AtroButton :pending="pending" :disabled="!valid">Save</AtroButton>
      </template>
    </Form>
  </AtroModal>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: Org
}

defineProps<Props>()
const model = defineModel<Props['modelValue']>()

const { closeModal } = useModal()
const { mutate: updateAdminOrg } = useMutation<Org>('updateAdminOrg')
const { onSubmit, pending } = useFormSubmit<Org>(async (data) => {
  await updateAdminOrg(data, { params: { orgId: data.id } })
  closeModal()
})
</script>
