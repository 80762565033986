<template>
  <AtroModal size="sm" :no-overlay="!hasOrg">
    <AtroModalHeader
      icon-wrapped
      :subtext
      icon="arrows-left-right"
      :text="headingText"
    />

    <AtroModalContent class="w-full space-y-2">
      <AtroButton
        v-for="otherOrg in orgs"
        block
        class="w-full truncate"
        type="light"
        :icon-left="org?.id === otherOrg.id ? 'check' : null"
        :key="otherOrg.id"
        :text="otherOrg.name"
        @click="onOrgClick(otherOrg)"
      />
    </AtroModalContent>

    <template v-if="isAdminOrOwner" #actions>
      <AtroButton
        block
        text="Create Another Organization"
        type="transparent"
        @click="navigateTo('/create-org')"
      />
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalOrgSwitcherProps {}

defineProps<ModalOrgSwitcherProps>()

const { isAdminOrOwner } = useCurrentUser()
const { org } = useCurrentOrg()
const { orgs } = useUserOrgs()

const hasOrg = computed(() => !!org.value)

const headingText = computed(() =>
  hasOrg.value ? 'Switch Org' : 'Select your account',
)
const subtext = computed(() =>
  hasOrg.value ? `Logged in to ${org.value?.name} account` : undefined,
)

async function onOrgClick(org: Org) {
  if (
    org.userRole === 'member' ||
    org.uiFlags?.onboardingV2?.hasCompletedOnboardingSteps ||
    org.uiFlags.onboardingV2?.walkthroughStatus === 'skipped'
  ) {
    await navigateTo(`/orgs/${org.slug}`)
  } else {
    await navigateTo(`/welcome?org=${org.slug}`)
  }
}
</script>
