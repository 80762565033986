import dot from 'dot-object'

type Scope = 'org' | 'user'

export function useUiConfig() {
  const { data } = useAuth()
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  const { mutate: updateOrg } = useMutation<Org>('updateOrg')
  const { mutate: updateUser } = useMutation<User>('updateProfile')

  const uiUserState = useState(() => ({ ...user.value?.uiFlags }))
  const uiOrgState = useState(() => ({ ...org.value?.uiFlags }))

  watch(org, (newOrg, oldOrg) => {
    if (newOrg?.id !== oldOrg?.id) {
      // @ts-ignore
      uiOrgState.value = deepToRaw(org.value?.uiFlags)
    }
  })

  watch(user, (newUser, oldUser) => {
    if (newUser?.id !== oldUser?.id) {
      // @ts-ignore
      uiUserState.value = deepToRaw(user.value?.uiFlags)
    }
  })

  function getState(scope: Scope, path: string) {
    if (scope === 'org') {
      return dot.pick(path, uiOrgState.value)
    } else if (scope === 'user') {
      return dot.pick(path, uiUserState.value)
    }
  }

  function saveUiState(scope: Scope) {
    if (scope === 'org') {
      return updateOrg({ uiFlags: uiOrgState.value })
    } else if (scope === 'user') {
      return updateUser({ uiFlags: uiUserState.value })
    }
  }

  function setUiState(scope: 'user', path: string, val: any): Promise<User>
  function setUiState(scope: 'org', path: string, val: any): Promise<Org>
  function setUiState(scope: Scope, path: string, val: any) {
    const stateToModifiy = scope === 'org' ? uiOrgState : uiUserState
    dot.set(path, val, unref(stateToModifiy) || {})
    return saveUiState(scope)
  }

  return {
    uiOrgState,
    uiUserState,
    uiContent: computed(() => data.value?.uiContent),
    getState,
    saveUiState,
    setUiState,
  }
}
