<template>
  <AtroModal full-height fullscreen-when-mobile no-padding size="xl">
    <MainContentCard class="text-left">
      <MainContent>
        <FlowAtomReader
          v-if="isReadMode"
          v-model="editablePolicyTemplate.template"
          class="h-full"
        />
        <Editor
          v-else
          class="h-full"
          v-model="editablePolicyTemplate.template"
        />
      </MainContent>
      <AsideContent>
        <AtroContent class="w-full flex-1" direction="col" wrap="nowrap">
          <AtroHeading
            v-if="!isEditing"
            semibold
            class="whitespace-normal text-atro-slate-purple"
            :text="editablePolicyTemplate.title"
          />
          <Form
            v-else
            v-model="editablePolicyTemplate"
            :actions="false"
            :id="formId"
            @submit="onSubmit"
          >
            <FormKit
              type="text"
              name="title"
              label="Template name"
              validation="required"
            />
          </Form>
          <AtroContent
            v-if="!isEditing"
            class="w-full"
            direction="col"
            wrap="nowrap"
          >
            <AtroMenuItem
              :is-mobile="$isMobile.value"
              :item="{
                iconLeft: 'pen',
                label: 'Edit',
              }"
              @click="changeMode('edit')"
            />
            <AtroMenuItem
              :is-mobile="$isMobile.value"
              :item="{
                iconLeft: 'trash',
                label: 'Delete',
              }"
              @click="onDelete"
            />
          </AtroContent>
        </AtroContent>

        <AtroContent
          v-if="isEditing"
          class="mt-20 w-full space-y-4"
          direction="col"
          wrap="nowrap"
        >
          <AtroButton
            block
            :pending
            :disabled="!valid"
            text="Save"
            @click="onSubmit"
          />
          <AtroButton block text="Cancel" type="light" @click="cancelSave" />
        </AtroContent>
      </AsideContent>
    </MainContentCard>
  </AtroModal>
</template>
<script setup lang="ts">
import { getNode } from '@formkit/core'

export interface ModalPolicyTemplateProps {
  mode: 'edit' | 'new' | 'read'

  policyTemplate?: PolicyTemplate
}

const { mode, policyTemplate } = defineProps<ModalPolicyTemplateProps>()

const formId = useId()
const { closeModal, openModal } = useModal()
const { mutate: createPolicyTemplate } = useMutation<PolicyTemplate>(
  'createPolicyTemplate',
)
const { mutate: deletePolicyTemplate } = useMutation('deletePolicyTemplate')
const { mutate: savePolicyTemplate } =
  useMutation<PolicyTemplate>('savePolicyTemplate')

const _mode = ref(mode)
const form = ref()
const valid = ref(false)
const editablePolicyTemplate = ref<PolicyTemplate>({
  ...(policyTemplate || { id: '', title: '', template: '' }),
})
const isEditing = computed(() => ['edit', 'new'].includes(_mode.value))
const isEditMode = computed(() => _mode.value === 'edit')
const isReadMode = computed(() => _mode.value === 'read')

function changeMode(newMode: ModalPolicyTemplateProps['mode']) {
  _mode.value = newMode
}

function cancelSave() {
  isEditMode.value ? changeMode('read') : closeModal()
}

function onDelete() {
  openModal('confirm', {
    props: {
      onConfirm: async () => {
        await deletePolicyTemplate(editablePolicyTemplate.value.id)
        // close the parent modal right after this closes
        setTimeout(closeModal, 300)
        return true
      },
    },
  })
}

const { onSubmit, pending } = useFormSubmit(async () => {
  const promise = isEditMode.value
    ? savePolicyTemplate(editablePolicyTemplate.value)
    : createPolicyTemplate(editablePolicyTemplate.value)
  editablePolicyTemplate.value = await promise
  changeMode('read')
})

watchEffect(async () => {
  if (isEditing.value) {
    await nextTick()
    form.value = getNode(formId)
  }
})
watchEffect(async () => {
  if (isEditing.value) {
    valid.value = !!form.value?.context?.state.valid
  }
})
</script>
