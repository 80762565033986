<template>
  <NuxtLink v-if="to" :to="to" :target="target">
    <AtroButton v-bind="_props" @click="onAction" />
  </NuxtLink>
  <AtroButton v-else v-bind="_props" @click="onAction" />
</template>

<script setup lang="ts">
import type { ButtonProps } from '@atro/components'

export interface Props extends /* @vue-ignore */ ButtonProps {
  action?: FlowAction
  buttonType?: ButtonProps['type']
  target?: '_blank' | '_self' | '_parent' | '_top'
  text?: string
  to?: string
}
defineOptions({
  inheritAttrs: false,
})
const props = defineProps<Props>()
const attrs = useAttrs()
const { buttonType = 'primary' } = props

const _props = computed(() => {
  const p = { ...attrs, ...props }
  delete p.type
  p.type = buttonType
  return p
})

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const onAction = () => {
  props.action && emit('action', props.action)
}
</script>
