import { breakpointsTailwind } from '@vueuse/core'

export default function useIsMobile() {
  const isMobile = ref(false)
  const { isMobile: uAIsMobile } = useDevice()
  const breakpoints = useBreakpoints(breakpointsTailwind)

  watch(
    breakpoints.sm,
    () => {
      isMobile.value =
        (!breakpoints.isGreater('sm') && import.meta.client) ||
        !!(import.meta.server && uAIsMobile)
    },
    { immediate: true },
  )

  return isMobile
}
