type FetchParams = Parameters<typeof $fetch>

export const $delete = async <T>(
  url: FetchParams[0],
  opts?: FetchParams[1],
) => {
  return $fetch<T>(url, {
    ...opts,
    method: 'DELETE',
  })
}

export const $patch = async <T>(
  url: FetchParams[0],
  body: any,
  opts?: FetchParams[1],
) => {
  return $fetch<T>(url, {
    ...opts,
    body: { data: body },
    method: 'PATCH',
  })
}

export const $post = async <T>(
  url: FetchParams[0],
  body?: any,
  opts?: FetchParams[1],
) => {
  return $fetch<T>(url, {
    ...opts,
    body: { data: body },
    method: 'POST',
  })
}

export const $put = async <T>(
  url: FetchParams[0],
  body?: any,
  opts?: FetchParams[1],
) => {
  return $fetch<T>(url, {
    ...opts,
    body: { data: body },
    method: 'PUT',
  })
}

export function $upload(url: string, _body: any) {
  let body = _body
  if (!(body instanceof FormData)) {
    body = Object.entries(body).reduce(
      // @ts-ignore
      (fd, [key, val]) => (fd.append(key, val), fd), // eslint-disable-line no-sequences
      new FormData(),
    )
  }
  return $fetch(url, {
    body,
    method: 'POST',
    mode: 'no-cors',
  })
}
