<template>
  <AtroContent
    class="w-full space-y-8"
    direction="col"
    items="center"
    justify="center"
  >
    <FrameworkItemCard :framework-item />

    <AtroContent
      class="relative w-full"
      items="center"
      justify="center"
      :direction="$isMobile.value ? 'col' : 'row'"
    >
      <div
        :class="[
          'absolute left-1/2 z-0 w-px -translate-x-1/2 bg-atro-warm-gray',
          {
            '-top-8 h-24': flowGroups.length % 2 === 0,
            '-top-8 h-8': flowGroups.length % 2 !== 0,
          },
        ]"
      />
      <AtroContent class="gap-8" items="center" justify="center">
        <AtroCard
          v-for="flowGroup in flowGroups"
          :class="[
            'relative z-1 !h-[129px] !w-[134px] items-center justify-center gap-2 !border shadow-framework-item-started !transition-all',
            {
              'sm:after:-right-[39px] sm:after:left-auto sm:after:top-1/2 sm:after:h-px sm:after:w-[38px] sm:after:-translate-y-1/2 sm:after:translate-x-0': true,
              'after:absolute after:-bottom-[39px] after:left-1/2 after:-z-0 after:h-[38px] after:w-px after:-translate-x-1/2 after:bg-atro-warm-gray after:last:hidden': true,
              '!border-atro-purple': flowGroup.status === 'complete',
              'focus-visible::shadow-framework-item-started-active hover:shadow-framework-item-started-active':
                !!flowGroup.action,
            },
          ]"
          :clickable="!!flowGroup.action"
          :id="`flow-group-${toKebabCase(flowGroup.title)}`"
          :key="flowGroup.title"
          @click="
            flowGroup.action &&
              $emit('action', {
                type: flowGroup.action,
                data: flowGroup.actionPayload,
              })
          "
          @keydown.space.prevent="
            flowGroup.action &&
              $emit('action', {
                type: flowGroup.action,
                data: flowGroup.actionPayload,
              })
          "
        >
          <AtroIconWrap
            circle
            size="sm"
            :class="[
              {
                '!bg-atro-purple text-white': flowGroup.status === 'complete',
                '!border-atro-blue-75 text-atro-blue-75':
                  flowGroup.status === 'in_progress',
                '!border-atro-purple': flowGroup.status === 'not_started',
              },
            ]"
            :name="
              flowGroup.status === 'complete'
                ? 'check'
                : flowGroup.status === 'in_progress'
                  ? 'wrench'
                  : 'plus'
            "
          />
          <AtroSpan
            class="text-center leading-4 text-atro-slate-purple"
            size="sm"
            :text="flowGroup.title"
          />
        </AtroCard>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { toKebabCase } from 'js-convert-case'

export interface Props {
  flowGroups: FlowGroup[]

  icon: IconName
  status: FrameworkStatus
  title: string

  progress?: number
  progressText?: string
}

const props = defineProps<Props>()
defineEmits<{
  action: [action: FlowAction]
}>()

const frameworkItem = computed<FrameworkItem>(() => ({
  icon: props.icon,
  status: props.status,
  title: props.title,
  progress: props.progress,
  progressText: props.progressText,
}))
</script>
