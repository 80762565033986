<template>
  <AtroCard
    class="self-stretch overflow-hidden sm:flex-1"
    rounding="lg"
    type="flat"
  >
    <AtroContent
      class="w-full flex-1 bg-atro-warm-gray-3 p-4"
      direction="col"
      wrap="nowrap"
    >
      <AtroContent class="h-24 w-full gap-2 px-4" direction="col" wrap="nowrap">
        <AtroHeading
          semibold
          class="text-atro-slate-purple"
          size="xxl"
          :text="planTitle"
        />
        <AtroHeading
          semibold
          class="text-primary"
          :size="planType === 'free' ? 'xxl' : 'sm'"
          :text="price"
        />
      </AtroContent>

      <AtroDivider size="lg" />

      <AtroContent class="w-full px-4" direction="col" wrap="nowrap">
        <AtroSpan semibold class="text-atro-gray" text="What’s included" />
        <SubscriptionFeatureList :plan-type />

        <AtroContent class="mt-6 w-full" justify="center">
          <AtroButton
            v-if="planType === 'pro'"
            class="banner-gradient"
            icon-right="rocket-launch"
            text="Contact Sales"
            @click="emit('upgrade', planType)"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
export interface Props {
  planType: SubscriptionPlanType

  current?: boolean
}

const { planType } = defineProps<Props>()
const emit = defineEmits<{
  upgrade: [planType: string]
}>()

const planTitle = computed(() => {
  switch (planType) {
    case 'free':
      return 'Free'
    case 'pro':
      return 'Pro'
    default:
      return ''
  }
})

const price = computed(() => {
  switch (planType) {
    case 'free':
      return '$0'
    case 'pro':
      return 'Contact us'
    default:
      return ''
  }
})
</script>
