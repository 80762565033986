<template>
  <AtroContent
    class="w-full rounded-3xl bg-atro-dark-purple p-6 text-white"
    direction="col"
    items="center"
  >
    <AtroIcon class="mb-6 h-8 w-32 text-white" name="atro-logo-horizontal" />
    <AtroHeading semibold class="mb-2" size="xs"
      >Don’t wait to get hacked. Peace of mind starts here.</AtroHeading
    >
    <AtroParagraph size="sm">Sign up for our beta for free!</AtroParagraph>

    <AtroButton class="mt-6" type="light" @click="emit('action', action)"
      >Sign up free</AtroButton
    >
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  action: FlowAction
}

defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()
</script>
