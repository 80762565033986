<template>
  <AtroContent
    :class="{ 'pb-4 pl-4': size === 'sm' }"
    direction="col"
    items="center"
  >
    <AtroSpan
      semibold
      text="Risk Profile"
      :class="{
        'text-atro-slate-purple': mode === 'dark',
        'text-white': mode === 'light',
      }"
      :size="size === 'md' ? 'lg' : 'xxs'"
    />

    <!-- CHART -->
    <div
      :class="[
        'relative',
        {
          'mt-4 size-[220px] rounded-2xl border-2': size === 'md',
          'mt-1 size-[80px] rounded-xl border border-white/50': size === 'sm',
          'bg-white/15': mode === 'light',
        },
      ]"
    >
      <template v-if="size === 'md'">
        <!-- VERTICAL LINES -->
        <div
          v-for="i in 6"
          :key="i"
          class="absolute top-0 z-0 h-full w-px bg-atro-purple/5"
          :style="{ left: `${(100 / 7) * i}%` }"
        />
        <!-- HORIZONTAL LINES -->
        <div
          v-for="i in 6"
          :key="i"
          class="absolute left-0 z-0 h-px w-full bg-atro-purple/5"
          :style="{ top: `${(100 / 7) * i}%` }"
        />

        <!-- HIGH LABEL -->
        <AtroSpan
          v-if="size === 'md'"
          class="absolute right-1.5 top-1.5 text-atro-red"
          size="xxs"
          text="HIGH"
        />

        <!-- LOW LABEL -->
        <AtroSpan
          v-if="size === 'md'"
          class="absolute bottom-1.5 left-1.5 text-atro-green"
          size="xxs"
          text="LOW"
        />
      </template>

      <!-- X DIVIDER -->
      <AtroIcon
        class="absolute left-0 top-1/2 w-full -translate-y-1/2"
        name="graph-horizontal-divider"
      />
      <AtroSpan
        v-if="xLabel"
        size="xxs"
        :class="[
          'absolute -translate-y-full text-atro-gray',
          {
            '-bottom-[34px] left-3': size === 'sm',
            'left-6 top-1/2 uppercase': size === 'md',
          },
        ]"
        :semibold="size === 'md'"
        :text="xLabel"
      />

      <!-- Y DIVIDER -->
      <AtroIcon
        class="absolute left-1/2 top-0 h-full -translate-x-1/2"
        name="graph-vertical-divider"
      />
      <AtroSpan
        v-if="yLabel"
        size="xxs"
        :class="[
          'absolute -translate-x-2/3 -rotate-90 text-atro-gray',
          {
            '-left-1 top-8': size === 'sm',
            'left-1/2 top-2/3 uppercase': size === 'md',
          },
        ]"
        :semibold="size === 'md'"
        :text="yLabel"
      />

      <div
        v-for="dataPoint in data"
        :key="dataPoint.position.toString()"
        :class="[
          'absolute flex -translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full',
          {
            'size-2': size === 'sm',
            'size-6': size === 'md',
            'bg-atro-purple': mode === 'dark',
            'bg-white': !dataPoint.primary && mode === 'light',
          },
        ]"
        :style="{
          left: `${dataPoint.position[0] * 100}%`,
          bottom: `${dataPoint.position[1] * 100}%`,
        }"
      >
        <AtroIcon
          v-if="dataPoint.primary && size === 'md'"
          class="size-5 text-white"
          name="star"
        />
      </div>
    </div>

    <!-- DATA LEGEND -->
    <AtroContent
      v-if="hasLabels && size === 'md'"
      class="mt-3 w-full space-x-4"
    >
      <AtroContent
        v-for="dataPoint in data"
        :key="dataPoint.label"
        class="space-x-1"
        items="center"
        wrap="nowrap"
      >
        <div
          class="flex size-4 items-center justify-center rounded-full bg-atro-purple"
        >
          <AtroIcon
            v-if="dataPoint.primary"
            class="size-3 text-white"
            name="star"
          />
        </div>
        <AtroSpan
          semibold
          class="text-atro-gray"
          size="xxs"
          :text="dataPoint.label"
        />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  data: {
    position: [number, number]

    label?: string
    primary?: boolean
  }[]

  mode?: 'light' | 'dark'
  size?: 'sm' | 'md'
  xLabel?: string
  yLabel?: string
}

const { data, mode = 'dark', size = 'md' } = defineProps<Props>()

const hasLabels = computed(() => data.some((dataPoint) => !!dataPoint.label))
</script>
