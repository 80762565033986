type Options = {
  prose?: boolean
}

export default function useDownloadPDF() {
  function downloadPDF(
    name: string,
    content: string,
    options: Options = { prose: true },
  ) {
    const el = document.createElement('div')
    el.innerHTML = content
    if (options.prose) {
      el.className = 'prose max-w-3xl p-10 !font-sans'
      el.style.letterSpacing = '0.30px'
    }
    // remove all smart fields that break styling
    el.querySelectorAll('span[data-smart]').forEach((smartEl) => {
      smartEl.replaceWith(...smartEl.childNodes)
    })
    exportToPDF(
      `${name}.pdf`,
      el as HTMLElement,
      {
        orientation: 'portrait',
      },
      {
        autoPaging: 'text',
        width: 768,
        windowWidth: 1650,
        margin: 0,
      },
    )
  }

  return downloadPDF
}
