<template>
  <AtroModal size="lg">
    <AtroModalHeader text="What's new in Atro" />
    <AtroModalContent class="space-y-12">
      <AtroContent
        v-for="(release, i) in releases"
        :key="release.releaseNumber"
        class="w-full space-y-6"
        direction="col"
        wrap="nowrap"
      >
        <!-- RELEASE NUMBER -->
        <AtroContent>
          <AtroHeading class="text-atro-slate-purple" size="xs">
            Release {{ formatDate(release.timestamp) }}
          </AtroHeading>
        </AtroContent>

        <!-- FEATURES -->
        <AtroContent
          v-if="release.features?.length"
          class="w-full"
          direction="col"
        >
          <AtroContent
            v-for="(feature, k) in release.features"
            :key="feature.title"
            direction="col"
          >
            <AtroContent
              class="w-full gap-8"
              items="center"
              wrap="nowrap"
              :direction="
                $isMobile.value ? 'col' : k % 2 === 0 ? 'row' : 'row-reverse'
              "
            >
              <NuxtImg
                v-if="feature.image"
                class="max-w-80"
                :src="feature.image"
              />
              <AtroContent shrink class="w-full space-y-2 text-left">
                <AtroHeading
                  semibold
                  class="text-atro-slate-purple"
                  size="xs"
                  :text="feature.title"
                />
                <AtroMarkdown
                  class="text-sm leading-4 text-atro-gray"
                  :markdown="feature.body"
                />
              </AtroContent>
            </AtroContent>

            <AtroDivider v-if="k < release.features?.length - 1" size="xl" />
          </AtroContent>
        </AtroContent>

        <!-- BUG FIXES -->
        <AtroContent
          v-if="release.bugFixes?.length"
          class="w-full rounded-2xl bg-atro-warm-gray-2 px-4 py-3"
        >
          <AtroContent class="space-x-2">
            <AtroIcon
              class="inline-block size-6 text-atro-dark-purple-85"
              name="circle-info"
            />
            <AtroSpan class="text-atro-gray-2" text="Bug Fixes" />
          </AtroContent>

          <ul class="mt-2 w-full text-left">
            <li v-for="bugFix in release.bugFixes" :key="bugFix">
              {{ bugFix }}
            </li>
          </ul>
        </AtroContent>
      </AtroContent>
    </AtroModalContent>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalReleaseNotesProps {
  releases: ReleaseNote[]
}

const { releases } = defineProps<ModalReleaseNotesProps>()
const { markReleasesAsSeen } = useReleaseNotes()

onMounted(() =>
  markReleasesAsSeen(releases.map((release) => release.releaseNumber)),
)
</script>
