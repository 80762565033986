<template>
  <AtroModal>
    <AtroModalHeader icon-wrapped icon="plug" text="Manage connection" />
    <AtroModalContent>
      <AtroSpan v-if="deps.flow.data.createdBy?.displayName" class="text-atro-gray"
        >Authorized by {{ deps.flow.data.createdBy.displayName }}</AtroSpan
      >
      <AtroSpan v-if="deps.flow.data.connectedAt" class="text-atro-gray"
        >Connected on {{ formatDate(deps.flow.data.connectedAt) }}</AtroSpan
      >

      <AtroButton
        class="mt-8"
        icon-right="rotate"
        text="Refresh Data"
        type="light"
        :pending="pending"
        @click="onRefresh"
      />
    </AtroModalContent>
  </AtroModal>
</template>
<script setup lang="ts">
export interface Props {
  deps: { flow: Flow }
}

defineProps<Props>()
const emit = defineEmits<{
  modalEvent: [type: string, payload: any]
}>()
const { closeModal } = useModal()

const pending = ref(false)

function onRefresh() {
  pending.value = true
  emit('modalEvent', 'action', {
    type: 'refresh_connection',
    data: () => {
      pending.value = false
      closeModal()
    },
  })
}
</script>
