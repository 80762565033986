export const createDevice = async ({
  body,
  params,
}: {
  body: { name: string; platform: Device['platform'] }
  params: Parameters<typeof apiPaths.devices>[0]
}) => {
  const path = getApiPath('devices', params)
  return await $post<Device>(path, body)
}
