<template>
  <AtroModal no-padding class="!bg-atro-warm-gray-3" type="fullscreen">
    <AtroAsync skip-empty :fetch-request :animated="false">
      <Transition name="fade-fast" mode="out-in">
        <FlowMarketplaceIndex v-if="!slug" :category :categories :flows />
        <FlowMarketplaceShow v-else :flow="selectedFlow" />
      </Transition>
    </AtroAsync>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalFlowMarketplaceProps {
  category?: string
  slug?: string
}

const { slug } = defineProps<ModalFlowMarketplaceProps>()

const fetchRequest = useData<OrgFlowsResponse>('orgFlows')

const categories = computed(() => {
  return [...new Set(fetchRequest?.data.value?.categories)]
})

const flows = computed(() => {
  const orgFlows = fetchRequest?.data.value?.orgFlows || []
  const availableFlows = fetchRequest?.data.value?.flows || []

  // @ts-ignore
  return [].concat(orgFlows, availableFlows) as OrgFlow[]
})

const selectedFlow = computed(() => {
  return (slug && flows.value.find((flow) => flow.slug === slug)) as Flow
})
</script>
