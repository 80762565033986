export default function useMobileDrawer() {
  const mobileNavShown = useState('mobileNavShown', () => false)

  function closeDrawer() {
    mobileNavShown.value = false
  }

  function openDrawer() {
    mobileNavShown.value = true
  }

  return {
    closeDrawer,
    openDrawer,
    mobileNavShown,
  }
}
