import FloatingVue from 'floating-vue'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FloatingVue, {
    themes: {
      none: {},
      hotspot: {
        $extend: 'dropdown',
      },
      onboarding: {
        $extend: 'dropdown',
        $resetCss: true,
      },
    },
  })
})
