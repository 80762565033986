<template>
  <AtroContent
    :tabbable="!!frameworkItem.action"
    :class="[
      'rounded-4xl group relative z-1 h-[var(--framework-card-height)] w-[var(--framework-card-width)] scale-90 bg-white transition-all sm:scale-100',
      {
        'cursor-pointer': !!frameworkItem.action,
        'shadow-framework-item-not-started': isNotStarted,
        'hover:shadow-framework-item-not-started-active focus-visible:shadow-framework-item-not-started-active':
          isNotStarted && !!frameworkItem.action,
        'shadow-framework-item-started': !isNotStarted,
        'hover:shadow-framework-item-started-active focus-visible:shadow-framework-item-started-active':
          !isNotStarted && !!frameworkItem.action,
      },
    ]"
    @click="onClick"
    @keydown.space.prevent="onClick"
  >
    <!-- PROGRESS SVG -->
    <template v-if="!isNotStarted">
      <!-- Base Background Square -->
      <svg
        class="absolute"
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="130"
        viewBox="0 0 130 130"
        fill="none"
      >
        <rect
          x="0.109375"
          y="0.25"
          width="129.746"
          height="129.748"
          rx="32"
          fill="#DCDFFC"
        />
      </svg>

      <!-- Masked Progress Square -->
      <svg
        class="absolute"
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="130"
        viewBox="0 0 130 130"
        fill="none"
      >
        <mask
          id="framework-item-mask"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="130"
          height="130"
        >
          <rect x="0" y="0" width="130" height="130" rx="32" fill="white" />
        </mask>
        <g mask="url(#framework-item-mask)">
          <path ref="progressPath" fill="#5554C6" />
        </g>
      </svg>
    </template>

    <!-- Rest of the template remains the same -->
    <AtroContent class="relative h-full w-full p-2">
      <AtroContent
        :class="[
          'h-full w-full rounded-[24px] p-1 text-center transition-colors',
          {
            'text-black/15': isNotStarted,
            'group-hover:text-atro-purple group-focus-visible:text-atro-purple':
              isNotStarted && !!frameworkItem.action,
            'bg-white text-atro-purple': !isNotStarted,
          },
        ]"
        items="center"
        justify="center"
        direction="col"
        wrap="nowrap"
      >
        <AtroIcon class="size-12" :name="frameworkItem.icon" />
        <AtroSpan class="mt-1 leading-4" :text="frameworkItem.title" />
      </AtroContent>

      <AtroContent
        v-if="isInProgress && frameworkItem.progressText"
        class="absolute -bottom-2 left-1/2 -translate-x-1/2 rounded-full bg-atro-purple px-2 py-0.5 text-white"
      >
        <AtroSpan :text="frameworkItem.progressText" />
      </AtroContent>
      <AtroIcon
        v-if="isCompleted"
        class="absolute -bottom-1.5 left-1/2 size-6 -translate-x-1/2 rounded-full bg-white text-atro-purple"
        name="circle-check"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

export interface Props {
  frameworkItem: FrameworkItem
}

const { frameworkItem } = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const progressPath = ref()
const currentProgress = ref(0)

const isCompleted = computed(() => frameworkItem.status === 'complete')
const isNotStarted = computed(() => frameworkItem.status === 'not_started')
const isInProgress = computed(() => frameworkItem.status === 'in_progress')
const progressNormalized = computed(
  () => (isCompleted.value ? 1 : frameworkItem.progress || 0) * 100,
)

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees + 90) * Math.PI) / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    x,
    y,
    'L',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    'Z',
  ].join(' ')

  return d
}

function onClick() {
  if (frameworkItem.action) {
    emit('action', {
      type: frameworkItem.action,
      data: frameworkItem.actionPayload,
    })
  }
}

function updateProgress(progress: number) {
  const radius = 130
  const endAngle = Math.min(progress, 99.999999) * 3.6
  const pathData = describeArc(65, 65, radius, 0, endAngle)
  progressPath.value?.setAttribute('d', pathData)
}

// Animation setup
watchEffect(() => {
  if (import.meta.client)
    gsap.to(currentProgress, {
      value: progressNormalized.value,
      duration: 1.5,
      ease: 'power2.out',
      onUpdate: () => {
        updateProgress(currentProgress.value)
      },
    })
})
</script>
