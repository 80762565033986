import type { FormKitNode } from '@formkit/core'

export const usePageState = () => {
  const actionLabelOverride = useState('actionLabelOverride', () => null)
  const mobileAsideModalOpen = useState('mobileAsideModalOpen', () => false)
  const pageFocus = useState<'main' | 'modal'>('pageFocus', () => 'main')
  const pending = useState('pagePending', () => false)
  const pageForm = useState<FormKitNode | null>('pageForm')
  const scrollingEl = useState<HTMLElement | null>('scrollingEl')
  const valid = useState('pageValid', () => true)
  const pageScroll = useScroll(scrollingEl)

  function registerPageForm(form: FormKitNode) {
    pageForm.value = form
  }

  function reset() {
    actionLabelOverride.value = null
    mobileAsideModalOpen.value = false
    pageForm.value = null
    pageFocus.value = 'main'
    pending.value = false
    valid.value = true
    setTimeout(() => {
      pageScroll.y.value = 0
    }, 300)
  }

  return {
    actionLabelOverride,
    mobileAsideModalOpen,
    pageFocus,
    pageForm,
    pageScroll,
    pending,
    scrollingEl,
    registerPageForm,
    reset,
    valid,
  }
}
