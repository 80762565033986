import { toCamelCase } from 'js-convert-case'

export function useActionConfirm() {
  const { openModal } = useModal()
  let modalProps

  function confirmAction(
    actionType: string,
    _obj: any,
    onConfirm?: () => Promise<boolean> | boolean,
  ) {
    const promise = new Promise<boolean>((resolve, reject) => {
      const obj = unref(_obj)
      const confirmActionStaticContentKey = toCamelCase(
        `confirmAction:${actionType}`,
      )

      modalProps = obj.staticContent?.[confirmActionStaticContentKey]
      if (!modalProps)
        switch (actionType) {
          case 'notify':
            modalProps = {
              afterConfirmHeadingText: 'Reminder sent!',
              afterConfirmBodyText:
                'Member has been emailed a reminder to their work.',
              bodyText:
                'Email this member reminding them to complete their work in Atro',
              confirmActionText: 'Send',
              headingText: 'Remind member to finish work',
              iconName: 'bell',
            }
            break
          case 'notify_many':
            modalProps = {
              afterConfirmHeadingText: 'Reminder sent!',
              afterConfirmBodyText:
                'Members have been emailed a reminder to their work.',
              bodyText:
                'Email all assigned members reminding them to complete their work in Atro',
              confirmActionText: 'Send',
              headingText: 'Remind members to finish work',
              iconName: 'bell',
            }
            break
          case 'delete_item':
            modalProps = {
              afterConfirmHeadingText: 'Deleted!',
              afterConfirmButtonText: 'Ok',
              afterConfirmBodyText: 'The item has been deleted.',
              bodyText: 'This item will be deleted.',
              confirmActionText: 'Delete',
              headingText: 'Are you sure?',
              iconName: 'exclamation',
              iconType: 'warning',
            }
            break
        }

      if (modalProps) {
        modalProps.cancelable = true
        modalProps.onConfirm = async () => {
          let val = true
          if (onConfirm) {
            val = await onConfirm()
          }
          resolve(val)
          return val
        }
        openModal('confirm', { props: modalProps })
      } else {
        reject(
          new Error(
            `${confirmActionStaticContentKey} not found in static content`,
          ),
        )
      }
    })
    return promise
  }

  return { confirmAction }
}
