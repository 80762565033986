<template>
  <div class="w-full">
    <AtroSection title="Team Breakdown">
      <template #postfix>
        <AtroContent class="gap-2" items="center">
          <AtroSpan class="text-atro-gray" size="sm" text="Email Frequency" />
          <AtroBadge
            class="!py-0.5 capitalize"
            color="warmGray"
            :label="intensity"
          />
        </AtroContent>
      </template>
      <!-- BODY -->
      <template #default>
        <TeamPhishingReportTable
          :user-phishing-reports
          @send-test="onSendTest"
        />
      </template>
    </AtroSection>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  intensity: string
  userPhishingReports: UserPhishingReport[]
}

defineProps<Props>()

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function onSendTest(userId: string) {
  emit('action', {
    type: 'send_action',
    data: {
      actionSlug: 'queue_email',
      body: { userId },
    },
  })
}
</script>
