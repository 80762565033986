export const deleteAdminOrg = async ({
  params,
}: {
  params: Parameters<typeof apiPaths.adminOrg>[0]
}) => {
  const key = getApiResourceKey('adminOrgs')
  const path = getApiPath('adminOrg', params)

  return deleteResource(path, {
    key,
    id: params.orgId,
  })
}

export const setOrgPlan = async ({
  body,
  params,
}: {
  body: { id: string }
  params: Parameters<typeof apiPaths.adminOrgPlan>[0]
}) => {
  const key = getApiResourceKey('adminOrgs')
  const path = getApiPath('adminOrgPlan', params)

  return updateResource<OrgFlow>(path, { body, key }, 'PUT')
}

export const updateAdminOrg = async ({
  body,
  params,
}: {
  body: Org
  params: Parameters<typeof apiPaths.adminOrg>[0]
}) => {
  const key = getApiResourceKey('adminOrgs')
  const path = getApiPath('adminOrg', params)

  return updateResource(path, {
    body,
    key,
  })
}

export const setOrgSubscriptionState = async ({
  body,
  params,
}: {
  body: { subscriptionState: string }
  params: Parameters<typeof apiPaths.adminOrgSubscriptionState>[0]
}) => {
  const key = getApiResourceKey('adminOrgs')
  const path = getApiPath('adminOrgSubscriptionState', params)

  return updateResource<OrgFlow>(path, { body, key }, 'PUT')
}

export const setOrgTrialEndsAt = async ({
  body,
  params,
}: {
  body: { trialEndsAt: string }
  params: Parameters<typeof apiPaths.adminOrgTrialEndsAt>[0]
}) => {
  const key = getApiResourceKey('adminOrgs')
  const path = getApiPath('adminOrgTrialEndsAt', params)

  return updateResource<OrgFlow>(path, { body, key }, 'PUT')
}
