export default defineNuxtRouteMiddleware((to) => {
  const { org } = useCurrentOrg()
  const { getSession, status } = useAuth()
  const { isTrialExpired } = useCurrentSubscription()
  const { openModal } = useModal()
  const { mutate: downgradeSubscription } = useMutation('downgradeSubscription')
  if (
    status.value === 'authenticated' &&
    isTrialExpired.value &&
    org.value &&
    org.value.userRole === 'owner' &&
    import.meta.client
  ) {
    setTimeout(async () => {
      if (org.value) {
        openModal('subscriptionUpgrade')
        await downgradeSubscription()
        getSession()
      }
    }, 1000)
  }
})
