<template>
  <AtroContent class="mb-6 w-full" direction="col" items="center">
    <AtroContent
      class="w-full rounded-3xl border-2 border-primary bg-atro-warm-gray-2 px-6 py-10 text-center"
      direction="col"
      items="center"
      wrap="nowrap"
      ref="awardRef"
    >
      <AtroIcon class="mb-4 h-6 w-24" name="atro-logo-horizontal" />
      <AtroHeading
        class="mb-4 text-atro-slate-purple"
        size="xl"
        text="Certificate of Completion"
      />
      <AtroIcon class="mb-3 h-26 w-26" :name="icon" />
      <AtroParagraph
        semibold
        class="mb-4 text-atro-dark-purple"
        size="lg"
        :text="title"
      />
      <AtroSpan class="text-atro-dark-purple"
        >Completed by {{ _user.displayName }}</AtroSpan
      >
    </AtroContent>

    <AtroButton
      class="mt-6"
      text="Download Certificate"
      type="light"
      @click="download"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  deps: {
    currentUser: User
  }
  title: string

  icon?: IconName
  user?: User
}

const { deps, user, icon = 'trophy' } = defineProps<Props>()

const awardRef = ref()
const downloadImage = useDownloadHtmlToImage()

const _user = computed(() => user || deps.currentUser)

function download() {
  downloadImage('my-certificate', awardRef.value.$el)
}
</script>
