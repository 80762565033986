export default function useCurrentUser() {
  const { data } = useAuth()
  const { role } = useCurrentRole()

  const user = computed(() => data.value?.user as User)
  const hasJoined = computed(() => user.value?.joined)
  const isAdminOrOwner = computed(() => ['admin', 'owner'].includes(role.value))
  const isPartner = computed(() =>
    data.value?.orgs?.some((org) => org.isPartner && org.userRole !== 'member'),
  )
  const isSystemAdmin = computed(() => user.value?.systemRole === 'admin')
  const hasMultipleOrgs = computed(() => (data.value?.orgs.length || 0) > 1)

  return {
    hasJoined,
    hasMultipleOrgs,
    isAdminOrOwner,
    isPartner,
    isSystemAdmin,
    user,
  }
}
