<template>
  <AtroContent class="mb-14 w-full text-center" direction="col" items="center">
    <FlowAtomGraphic class="mb-0" v-bind="graphicProps" />
    <AtroHeading
      semibold
      class="mb-2 mt-4 text-atro-slate-purple"
      size="xl"
      :text="heading"
    />
    <SubscriptionProBadge v-if="showProBadge" class="mb-2" />
    <AtroParagraph class="text-atro-gray" :text />
    <FlowAtomWelcomeCTA :deps class="mt-6" />
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as GraphicProps } from '@/components/flow/atom/FlowAtomGraphic.vue'

export interface Props {
  deps: { flow: Flow }
  heading: string
  icon: IconName
  text: string

  graphic?: GraphicProps
}

const { deps, graphic, icon } = defineProps<Props>()

const { planLevel, isTrial } = useCurrentSubscription()

const showProBadge = computed(
  () =>
    planLevel.value < deps.flow.planLevel ||
    (isTrial.value && deps.flow.planLevel > 0),
)

// TODO: remove once legacy icon has been replaced with graphic
const graphicProps = computed(() => {
  if (graphic) return graphic
  return {
    icon,
    height: '72px',
    width: '72px',
  }
})
</script>
