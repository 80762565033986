<template>
  <AtroModal>
    <AtroModalHeader text="Set up a new assessment" />

    <AtroModalContent>
      <Form
        align="center"
        input-width="full"
        width="sm"
        v-model="formData"
        @submit="onSubmit"
      >
        <FormKit
          label="Email"
          name="email"
          placeholder="Email"
          type="email"
          validation="required|email"
        />
        <FormKit
          label="Company Name"
          name="name"
          placeholder="Company name"
          type="text"
          validation="required"
        />
        <FormKit
          label="First Name"
          name="firstName"
          placeholder="First Name"
          type="text"
          validation="required"
        />
        <FormKit
          label="Last Name"
          name="lastName"
          placeholder="Last Name"
          type="text"
          validation="required"
        />
        <FormKit
          type="select"
          label="Company Size"
          name="size"
          placeholder="Unselected"
          :options="COMPANY_SIZE_OPTIONS"
        />

        <template #submit="{ state: { valid } }">
          <AtroButton
            class="w-56 place-self-center"
            :pending
            text="Create Prospect"
            :disabled="!valid"
          />
        </template>
      </Form>
    </AtroModalContent>
  </AtroModal>
</template>
<script setup lang="ts">
export interface Props {}

defineProps<Props>()

const { getSession } = useAuth()
const { org } = useCurrentOrg()
const { closeModal } = useModal()

const formData = ref({
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  size: null,
})

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(async () => {
  await $post(getApiPath('prospects', { orgId: org.value?.id }), formData.value)
  refreshNuxtData('dashboardLeads')
  // refresh session to load newly created org
  getSession()
  closeModal()
})
</script>
