export const BASE_MOCK_PATH = import.meta.client
  ? window.location.origin
  : process.env?.API_HOST || ''

export const COMPANY_SIZE_OPTIONS: SelectOption[] = [
  {
    label: '< 10',
    value: '< 10',
  },
  {
    label: '10-50',
    value: '10-50',
  },
  {
    label: '50-200',
    value: '50-200',
  },
  {
    label: '200-500',
    value: '200-500',
  },
  {
    label: '500-1000',
    value: '500-1000',
  },
  {
    label: '> 1000',
    value: '> 1000',
  },
]

export const FLOW_ACTIVATED_STATES: FlowStatus[] = [
  'ok',
  'started',
  'warning',
  'waiting',
]

export const ROLES: Role[] = ['admin', 'member']

export const ROLE_OPTIONS = [
  {
    label: '— Select —',
    value: '-----',
    attrs: { disabled: true },
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Member',
    value: 'member',
  },
]

export const SYSTEM_ADMINS = [
  'jonathan@atro.com',
  'k@atro.com',
  'kb@atro.com',
  'kasey@atro.com',
  'danc@atro.com',
  'pat@atro.com',
  'philipp@atro.com',
]
