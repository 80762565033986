<template>
  <AtroHeading
    centered
    semibold
    class="text-atro-slate-purple"
    size="sm"
    :text="step.headerText"
  />

  <AtroParagraph
    v-if="step.headerSubtext"
    centered
    class="mb-6 mt-2 text-atro-gray"
    size="sm"
    :text="step.headerSubtext"
  />

  <Form input-width="full" @submit="onSubmit">
    <FormKit
      type="tagCloud"
      v-model="model"
      :options="step.options"
      :props="{ hasOtherOption: !!step.hasOtherOption }"
    />

    <template #submit="{ state: { valid } }">
      <AtroButton
        :pending
        class="place-self-center"
        text="Continue"
        :disabled="!valid"
      />
    </template>
  </Form>
</template>

<script setup lang="ts">
export interface Props {
  step: OnboardingStep
  modelValue: string[]
}

defineProps<Props>()
const model = defineModel<Props['modelValue']>({ default: [] })
const emit = defineEmits<{
  complete: [selectedOptions: string[]]
}>()

const { onSubmit, pending } = useFormSubmit(async () => {
  emit('complete', model.value)
  await wait(300)
})
</script>
