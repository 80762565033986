<template>
  <AtroContent
    class="sticky top-0 z-100 h-[var(--top-nav-height)] w-full bg-atro-warm-gray-3/90 shadow-[0_6px_12px_0px_rgba(251,250,249,0.9)] backdrop-blur-sm"
    justify="center"
  >
    <AtroContent
      class="h-full w-full max-w-[var(--home-content-width)] px-10"
      items="center"
      justify="between"
    >
      <!-- LEFT -->
      <AtroContent>
        <AtroContent class="relative text-primary" direction="col">
          <!-- CUSTOM LOGO -->
          <AtroContent v-if="logo" class="w-full gap-4" wrap="nowrap">
            <NuxtImg class="max-h-[var(--logo-size)] self-center" :src="logo" />
            <AtroContent
              class="gap-1.5 place-self-end text-atro-gray"
              items="end"
              justify="center"
            >
              <AtroSpan class="!text-[9px] leading-none" text="Powered by" />
              <AtroIcon class="h-4" name="atro-logo-horizontal" />
            </AtroContent>
          </AtroContent>
          <!-- DEFAULT LOGO -->
          <AtroIcon
            v-else
            class="h-[var(--logo-size)] w-[165px]"
            name="atro-logo-horizontal"
          />
        </AtroContent>
      </AtroContent>

      <!-- RIGHT -->
      <AtroContent v-if="user" class="gap-10" items="center" wrap="nowrap">
        <AtroContent
          v-if="showActions"
          v-tooltip="onboardingAssignedTooltip"
          class="group cursor-pointer gap-2 text-atro-gray"
          items="center"
          wrap="nowrap"
          @click="toggleView()"
        >
          <AtroSpan
            class="focus-visible:text-primary group-hover:text-primary"
            size="sm"
            tabindex="0"
            :text="navToggleText"
            @keydown.space.prevent="toggleView()"
          />
          <div v-if="navToggleCount > 0" class="relative flex">
            <AtroDot
              class="absolute mt-0.5 inline-flex animate-ping bg-atro-red"
              size="md"
            />
            <AtroDot
              class="relative mt-0.5 inline-flex bg-atro-red"
              size="md"
            />
          </div>
        </AtroContent>

        <UserMenu />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
const router = useRouter()
const { isAdminOrOwner, user } = useCurrentUser()
const { org } = useCurrentOrg()
const { navToggleCount, toggleView, view: userView } = useHomeNav()
const { onboardingAssignedTooltip } = useTooltips()

const logo = computed(
  () => orgLogo.value || partnerLogo.value || parentLogo.value,
)
const orgLogo = computed(() => org.value?.logo)
const parentLogo = computed(() => org.value?.parent?.partnerProfile?.logo)
const partnerLogo = computed(() => org.value?.partnerProfile?.logo)

const navToggleText = computed(() => {
  switch (userView.value) {
    case 'admin':
      return 'Show assigned to me'
    case 'member':
      return 'Go to admin console'
    default:
      return ''
  }
})
const showActions = computed(
  () => router.currentRoute.value.name === 'orgs-slug' && isAdminOrOwner.value,
)
</script>
