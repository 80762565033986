export function useUserOrgs() {
  const { data } = useAuth()

  const hasMultipleOrgs = computed(() => orgs.value.length > 1)
  const isAdminInAnyOrg = computed(() =>
    orgs.value.some((org) => ['admin', 'owner'].includes(org.userRole)),
  )
  const missingOrg = computed(() => orgs.value.length === 0)
  const orgs = computed(() => (data.value?.orgs || []) as Org[])
  const parentOrg = computed(() =>
    orgs.value.find((org) => org.isPartner && org.userRole !== 'member'),
  )

  return {
    hasMultipleOrgs,
    isAdminInAnyOrg,
    missingOrg,
    orgs,
    parentOrg,
  }
}
