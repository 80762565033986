<template>
  <FlowAtomCard
    v-bind="props"
    class="w-full"
    size="sm"
    @action="(action) => $emit('action', action)"
  >
    <template #topRight>
      <AtroIcon
        :class="['size-5', { 'text-atro-green': status === 'ok' }]"
        :name="statusIcon"
      />
    </template>
  </FlowAtomCard>
</template>

<script setup lang="ts">
export interface Props {
  title: string
  status: 'ok' | 'waiting'

  action?: FlowAction
  direction?: 'col' | 'row'
  icon?: IconName
  text?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()

const statusIcon = computed<IconName>(() =>
  props.status === 'ok' ? 'circle-check' : 'circle-plus-gray',
)

onMounted(() => emit('validityChange', props.status === 'ok'))
</script>
