<template>
  <LottiAnimation
    loop
    :class="[
      'pointer-events-none inset-0',
      {
        absolute: type === 'container',
        fixed: type === 'screen',
      },
    ]"
    :animation-data="confettiJSON"
  />
</template>

<script setup lang="ts">
export interface Props {
  type?: 'container' | 'screen'
}

const { type = 'screen' } = defineProps<Props>()

const confettiJSON = (await import('@/assets/animations/confetti.json')).default
</script>
