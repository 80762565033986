export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('auto-expand', {
    mounted(el, binding) {
      const { y: scrollY } = useWindowScroll()
      const elBounding = useElementBounding(el)

      function expandContent() {
        const elOffset = el.getBoundingClientRect()
        const userTopOffset = binding.value.topOffset || 0
        const userBottomOffset = binding.value.bottomOffset || 0
        const userOffset = userTopOffset + userBottomOffset
        const scrollyOffset = Math.max(scrollY.value, userTopOffset)
        const offset = Math.max(
          elOffset.y + userOffset - scrollyOffset,
          userOffset,
        )
        el.style.height = `calc(100svh - ${offset}px)`
      }

      watch(
        Object.values(elBounding),
        () => {
          requestAnimationFrame(expandContent)
        },
        { immediate: true },
      )
    },
  })
})
