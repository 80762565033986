export default function useUserNav() {
  const router = useRouter()
  const { signOut } = useAuth()
  const { isAdmin, isMember, isOwner } = useCurrentRole()
  const { hasMultipleOrgs, isPartner, isSystemAdmin } = useCurrentUser()
  const { org } = useCurrentOrg()
  const { openModal } = useModal()

  const items = computed(() => {
    const adminDashboard: MenuItem = {
      label: 'Admin Dashboard',
      iconLeft: 'rocket-launch',
      linkTo: wrapPathWithOrg('/'),
    }
    const createOrg: MenuItem = {
      label: 'Create New Organization',
      iconLeft: 'plus',
      linkTo: '/create-org',
    }
    const logoutNavItem: MenuItem = {
      label: 'Log out',
      iconLeft: 'logout',
      action: 'signOut',
    }
    const manageOrgNavItem: MenuItem = {
      label: 'Organization Settings',
      iconLeft: 'suitecase',
      linkTo: wrapPathWithOrg('/manage/org'),
    }
    const manageTeamNavItem: MenuItem = {
      label: 'Manage Team',
      iconLeft: 'users',
      linkTo: wrapPathWithOrg('/manage/team'),
    }
    const partnerDashboard: MenuItem = {
      label: 'Partner Dashboard',
      iconLeft: 'rocket-launch',
      linkTo: wrapPathWithParentOrg('/dashboard'),
    }
    const switchOrg: MenuItem = {
      label: org.value ? 'Switch Organization' : 'Choose Org',
      iconLeft: 'arrows-left-right',
      action: 'switchOrg',
    }
    const systemAdmin: MenuItem = {
      label: 'System Admin',
      iconLeft: 'screwdriver-wrench-light',
      linkTo: '/admin/orgs',
    }
    return {
      adminDashboard,
      createOrg,
      logoutNavItem,
      manageOrgNavItem,
      manageTeamNavItem,
      partnerDashboard,
      systemAdmin,
      switchOrg,
    }
  })

  const menuItems = computed(() => {
    const itemsToRender = [] as MenuItem[]
    const _items = unref(items)

    if (isSystemAdmin.value && router.currentRoute.value.name !== 'admin-orgs')
      itemsToRender.push(_items.systemAdmin)

    if (isOwner.value) {
      itemsToRender.push(_items.manageTeamNavItem, _items.manageOrgNavItem)
    }
    if (isAdmin.value) {
      itemsToRender.push(_items.manageTeamNavItem)
    }
    if (isPartner.value) {
      itemsToRender.push(
        router.currentRoute.value.name
          ?.toString()
          .includes('orgs-slug-dashboard')
          ? _items.adminDashboard
          : _items.partnerDashboard,
      )
    }
    if (hasMultipleOrgs.value) {
      itemsToRender.push(_items.switchOrg)
    } else if (!isMember.value) {
      itemsToRender.push(_items.createOrg)
    }

    itemsToRender.push(_items.logoutNavItem)

    return itemsToRender
  })

  const onAction = (actionName: string) => {
    switch (actionName) {
      case 'signOut':
        signOut({
          callbackUrl: '/login',
        })
        break
      case 'switchOrg':
        openModal('orgSwitcher')
        break
      default:
        break
    }
  }

  return { menuItems, onAction }
}
