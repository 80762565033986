<template>
  <AtroModal v-slot="{ close }" size="sm">
    <TeamInviteContent>
      <template #afterSendActions>
        <AtroButton block class="mt-4" text="Done" @click="close" />
      </template>
    </TeamInviteContent>
  </AtroModal>
</template>

<script setup lang="ts"></script>
