import { formatDistanceToNowStrict } from 'date-fns'

export default function useCurrentSubscription() {
  const router = useRouter()
  const { isKioskMode, org } = useCurrentOrg()
  const { hasJoined } = useCurrentUser()
  const { layoutConfig } = useLayoutConfig()

  const subscription = computed(() => org.value?.subscription as Subscription)
  const plan = computed(() => subscription.value?.plan)
  const planLevel = computed(() => plan.value?.level || 0)

  const isBelowOrgFlowLimit = computed(
    () => subscription.value?.remainingOrgFlows > 0,
  )
  const isBelowUserLimit = computed(
    () => subscription.value?.remainingUsers > 0,
  )
  const isFreePlan = computed(() => plan.value?.level === 0)
  const isTrial = computed(() =>
    ['trial', 'trial_expired'].includes(subscription.value?.state),
  )
  const isTrialExpired = computed(
    () => subscription.value?.state === 'trial_expired',
  )
  const showSubscribeBanner = computed(
    () =>
      hasJoined.value &&
      !isKioskMode.value &&
      !isPayingUser.value &&
      !layoutConfig.value.hideSubscribeBanner &&
      router.currentRoute.value.name !== 'orgs-slug-welcome',
  )
  const subscribeAnnuallyUrl = computed(
    () => subscription.value?.stripeAnnualLink,
  )
  const subscribeMonthlyUrl = computed(
    () => subscription.value?.stripeMonthlyLink,
  )
  const trialExpiresDate = computed(
    () =>
      subscription.value?.trialEndsAt &&
      new Date(subscription.value?.trialEndsAt),
  )
  const isPayingUser = computed(() => !isTrial.value && !isFreePlan.value)

  const trialRemaingDays = computed(
    () =>
      trialExpiresDate.value &&
      formatDistanceToNowStrict(trialExpiresDate.value, {
        unit: 'day',
      }),
  )

  return {
    isBelowOrgFlowLimit,
    isBelowUserLimit,
    isFreePlan,
    isPayingUser,
    isTrial,
    isTrialExpired,
    plan,
    planLevel,
    showSubscribeBanner,
    subscribeAnnuallyUrl,
    subscribeMonthlyUrl,
    subscription,
    trialExpiresDate,
    trialRemaingDays,
  }
}
