export function usePendingWrap(_pending?: Ref<boolean>) {
  const pending = _pending || ref(false)

  async function withPending(promise: Promise<any>) {
    pending.value = true
    try {
      const response = await promise
      pending.value = false
      return response
    } catch (e) {
      pending.value = false
      throw e
    }
  }

  function wrapWithPending<T>(wrappedCb: (...args: any[]) => Promise<T>) {
    return async function (...args: any[]) {
      return await withPending(wrappedCb(...args))
    }
  }

  return { pending, withPending, wrapWithPending }
}
