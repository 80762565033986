<template>
  <NuxtLink
    tabindex="-1"
    :to="flowPath"
    @keydown.space.prevent="() => navigateTo(flowPath)"
  >
    <AtroCard
      rounding="2xl"
      :clickable
      :class="[
        'flow-card group mx-auto !h-[var(--flow-card-height)] !w-[var(--flow-card-width)] justify-between bg-white !p-2 shadow-card',
        {
          'select-none': !clickable,
        },
      ]"
    >
      <div
        :class="['h-full w-full', { 'overflow-hidden': showFlowDescription }]"
      >
        <AtroContent
          class="relative mb-2 h-6 w-full px-2 pt-1"
          justify="between"
          wrap="nowrap"
        >
          <AtroContent class="w-full">
            <!-- NEEDS SETUP BADGE -->
            <AtroContent
              v-if="showNeedsSetupBadge"
              class="w-full rounded-2xl bg-atro-blue-15 p-1 text-atro-blue transition-opacity group-hover:opacity-0"
              items="center"
              justify="center"
            >
              <AtroIcon class="mr-1.5 size-2.5" name="wrench" />
              <AtroSpan
                semibold
                class="leading-none"
                size="xs"
                text="Finish Setup"
              />
            </AtroContent>
            <!-- STATUS ICON -->
            <AtroContent
              v-else-if="statusIcon"
              :class="[
                statusColor,
                'absolute left-2 top-1 bg-transparent transition-colors',
                [
                  view !== 'marketplace' && {
                    'rounded-2xl py-0.5 pl-0.5 pr-2': true,
                    'group-hover:!bg-atro-yellow-15': isLocked,
                    'group-hover:bg-atro-green-15': flow.status === 'ok',
                    'group-hover:bg-atro-red-15': flow.status === 'warning',
                    'group-hover:bg-atro-blue-15': !['ok', 'warning'].includes(
                      flow.status,
                    ),
                  },
                ],
              ]"
              items="center"
            >
              <AtroIcon
                :class="[
                  'size-4 transition-opacity',
                  {
                    'opacity-0 group-hover:opacity-100': isLocked,
                  },
                ]"
                :name="statusIcon"
              />
              <AtroSpan
                v-if="view !== 'marketplace'"
                class="ml-1 leading-none opacity-0 transition-opacity group-hover:opacity-100"
                semibold
                size="xs"
                :text="statusText"
              />
            </AtroContent>
            <!-- PRO CROWN -->
            <AtroIcon
              v-else-if="showCrown"
              class="relative size-4 text-atro-yellow opacity-0 transition-opacity group-hover:opacity-100"
              name="crown"
            />
          </AtroContent>

          <OverflowMenu
            v-if="view === 'admin'"
            class="absolute -top-1 right-8 opacity-0 transition-all group-hover:opacity-100"
            icon-class="!size-4"
            menu-button-class="!p-2"
            size="sm"
            :items="OVERFLOW_MENU_ITEMS"
            @action="onMenuAction"
          />
        </AtroContent>

        <AtroContent
          class="w-full flex-1 px-2"
          direction="col"
          items="center"
          wrap="nowrap"
        >
          <AtroIcon
            :class="[
              'mb-3 size-10 text-atro-slate-purple',
              {
                'transition-all group-hover:-translate-y-14 group-hover:opacity-0':
                  showFlowDescription,
              },
            ]"
            :name="flow.icon"
          />
          <div
            :class="[
              'w-full text-center',
              {
                'transition-all group-hover:-translate-y-12':
                  showFlowDescription,
              },
            ]"
          >
            <AtroParagraph
              semibold
              class="text-atro-slate-purple"
              size="sm-short"
              :text="flow.title"
            />

            <AtroSpan
              v-if="showFlowDescription"
              semibold
              class="mt-2 line-clamp-3 text-atro-gray opacity-0 transition-all group-hover:opacity-100"
              size="xxs"
              :text="description"
            />
          </div>
        </AtroContent>
      </div>

      <AtroContent
        v-if="flow.selfReported"
        class="absolute -bottom-3 left-1/2 -translate-x-1/2 whitespace-nowrap rounded-full border bg-atro-warm-gray-2 px-3 py-1 transition-all group-hover:border-primary"
        items="center"
        justify="center"
        wrap="nowrap"
      >
        <AtroSpan
          semibold
          class="text-atro-gray"
          size="xxs"
          text="Self Reported"
        />
      </AtroContent>
    </AtroCard>
  </NuxtLink>
</template>

<script setup lang="ts">
export interface Props {
  flow: OrgFlow | UserFlow
  view: FlowCardView
}

const OVERFLOW_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Restart',
    iconLeft: 'rotate',
    action: 'restart',
  },
  {
    label: 'Delete',
    iconLeft: 'trash',
    action: 'delete',
  },
]

const { flow, view } = defineProps<Props>()

const { mutate: deleteOrgFlow } = useMutation('deleteOrgFlow')
const { mutate: restartOrgFlow } = useMutation<OrgFlow>('restartOrgFlow')

const {
  flowPath,
  isComingSoon,
  isLocked,
  needsSetup,
  statusColor: flowStatusColor,
  statusIcon: flowStatusIcon,
} = useFlowMeta(
  computed(() => flow),
  view,
)
const { openModal } = useModal()

const clickable = computed(() => !isComingSoon.value && view !== 'preview')
const description = computed(() =>
  isComingSoon.value ? 'Coming soon!' : flow.description,
)
const showAdded = computed(
  () =>
    !['available', 'coming_soon'].includes(flow.status) &&
    view === 'marketplace',
)
const showCrown = computed(
  () => view === 'marketplace' && !isComingSoon.value && isLocked.value,
)
const showFlowDescription = computed(() => view === 'marketplace')
const showNeedsSetupBadge = computed(() => needsSetup.value && view === 'admin')

const statusColor = computed(() => {
  if (showAdded.value) {
    return 'text-atro-gray'
  }
  if (isLocked.value && !needsSetup.value) {
    return 'text-atro-yellow'
  }
  return flowStatusColor.value
})

const statusIcon = computed<IconName | null>(() => {
  if (showAdded.value) {
    return 'check'
  }
  if (isLocked.value && view !== 'marketplace') {
    return 'lock'
  }
  return flowStatusIcon.value
})

const statusText = computed(() => {
  if (isLocked.value) return 'Locked'
  switch (flow.status) {
    case 'ok':
      return 'All good!'
    case 'warning':
      return flow.alerts?.length
        ? `${flow.alerts?.length} ${flow.alerts?.length === 1 ? 'Alert' : 'Alerts'}`
        : 'Alert'
    default:
      return 'In progress'
  }
})

async function onMenuAction(actionName: string) {
  switch (actionName) {
    case 'delete':
      openModal('delete', {
        props: {
          bodyText:
            'This will remove the topic from the dashboard and delete all associated data',
          confirmActionText: 'Delete topic',
          onDelete: async () => {
            await deleteOrgFlow(flow.id)
            return true
          },
        },
      })
      break
    case 'restart':
      openModal('confirm', {
        props: {
          bodyText:
            'This will reset this topic to an un-started state and delete all associated data',
          confirmActionText: 'Restart topic',
          confirmInputText: 'RESTART',
          size: 'sm',
          onConfirm: async () => {
            const newOrgFlow = await restartOrgFlow(flow.id)
            navigateToWithOrg(`/org-flows/${newOrgFlow.id}/setup`)
          },
        },
      })
      break
    default:
      break
  }
}
</script>
