import type { FormKitNode } from '@formkit/core'

export default function useFormSubmit<T>(
  cb: (formData: T, node: FormKitNode | undefined) => Promise<any>,
  options?: {
    errorCodeOverrides: Record<number, any>
  },
) {
  const pending = ref(false)

  const onSubmit = async (formData: T, node: FormKitNode | undefined) => {
    pending.value = true
    try {
      await cb(formData, node)
    } catch (e) {
      handleFormError(e, node, options?.errorCodeOverrides)
    }
    await delay(50)
    pending.value = false
  }

  return {
    onSubmit,
    pending,
  }
}
