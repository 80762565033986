<template>
  <div class="w-full">
    <AtroSection title="Team Breakdown">
      <template #postfix>
        <AtroContent class="gap-2" items="center">
          <AtroSpan
            class="text-atro-gray"
            size="sm"
            text="Training Frequency"
          />
          <AtroBadge
            class="!py-0.5 capitalize"
            color="warmGray"
            :label="intensity"
          />
        </AtroContent>
      </template>

      <!-- BODY -->
      <template #default>
        <UserAwarnessReportTable :user-awareness-reports @retrain="onRetrain" />
      </template>
    </AtroSection>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  intensity: string
  retrainAction: FlowActionType
  userAwarenessReports: UserAwarenessReport[]
}

const { retrainAction } = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function onRetrain(userId: string) {
  emit('action', {
    type: 'send_action',
    data: { actionSlug: retrainAction, body: { userId } },
  })
}
</script>
