export default function useMarketplace() {
  const { getModalDeepLinkTo, openModal } = useModal()

  function getMarketplaceDetailLinkTo(slug: string) {
    return getModalDeepLinkTo('flowMarketplace', {
      props: { slug },
      replace: true,
      updateInPlace: true,
    })
  }

  function openMarketplace(props?: any, replace?: boolean) {
    openModal('flowMarketplace', {
      props,
      replace,
      deep: true,
      updateInPlace: true,
    })
  }

  return { getMarketplaceDetailLinkTo, openMarketplace }
}
