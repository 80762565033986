<template>
  <component
    v-for="modal in modalStack"
    v-bind="modal.options?.props"
    :key="modal.hash"
    :is="modal.component"
    :is-mobile="$isMobile.value"
    :is-open="modal.isOpen"
    @after-close="afterClose"
    @close="closeModal(modal.hash)"
    @modal-event="handleModalEvent"
  />
</template>

<script setup lang="ts">
const { modalStack, afterClose, closeModal, handleModalEvent } = useModal()
</script>
