<template>
  <AtroContent class="w-full space-y-6">
    <AtroContent class="w-full space-y-6 sm:space-y-0" justify="between">
      <FlowAtomDataCard
        v-bind="dataCard"
        @action="(action: FlowAction) => emit('action', action)"
      />
      <AtroContent class="w-full sm:w-auto" justify="center">
        <FlowAtomDataItem
          v-for="(dataItem, i) in dataItems"
          :key="i"
          v-bind="dataItem"
        />
      </AtroContent>
    </AtroContent>

    <FlowAtomDataBarChart v-bind="barChart" v-model="selectedChartKey" />

    <AtroContent
      v-if="subitems?.length"
      class="w-full rounded-lg bg-atro-warm-gray-2"
    >
      <FlowAtomListItemContent
        class="!border-0"
        v-bind="listItem"
        :subitems
        @action="(action: FlowAction) => emit('action', action)"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as DataBarChartProps } from '~/components/flow/atom/FlowAtomDataBarChart.vue'
import type { Props as DataItemProps } from '~/components/flow/atom/FlowAtomDataItem.vue'
import type { Props as DataCardProps } from '~/components/flow/atom/FlowAtomDataCard.vue'
import type { Props as ListItemContentProps } from '~/components/flow/atom/FlowAtomListItemContent.vue'

export interface Props {
  barChart: DataBarChartProps
  dataCard: DataCardProps
  dataItems: DataItemProps[]
  listItem: ListItemContentProps

  userBuckets?: Record<string, string[]>
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const selectedChartKey = ref('')
const selectedBucket = computed(
  () => props.userBuckets?.[selectedChartKey.value],
)
const subitems = computed(() =>
  selectedBucket.value
    ? props.listItem.subitems?.filter((subitem) =>
        selectedBucket.value?.includes(subitem.data.id),
      )
    : props.listItem.subitems,
)
</script>
