<template>
  <AtroContent
    :class="[
      'w-full gap-6',
      { 'rounded-2xl bg-atro-warm-gray-2 p-6 pb-2': type === 'bubble' },
    ]"
    direction="col"
  >
    <AtroContent
      v-if="!hideTextOnSelection || (hideTextOnSelection && !model)"
      class="w-full gap-2"
      direction="col"
    >
      <AtroMarkdown
        v-if="title"
        :class="[
          'text-atro-slate-purple',
          {
            'text-lg': type === 'bubble',
            'text-3xl font-semibold': type === 'text',
          },
        ]"
        :markdown="title"
      />
      <AtroMarkdown
        v-if="subtitle"
        :class="[
          'text-atro-gray',
          {
            'text-sm': type === 'bubble',
            'text-lg': type === 'text',
          },
        ]"
        :markdown="subtitle"
      />
    </AtroContent>

    <AtroContent :class="['w-full']">
      <slot>
        <FlowAtomOption
          v-for="option in options"
          :class="{
            'mb-4 mr-4': type === 'bubble',
            'w-full': type === 'text',
          }"
          :option
          :type
          :key="option.value"
          :active="option.value === modelValue"
          :has-correct-option="!!correctOption"
          :correct="
            selectedOption?.value === option.value && correctOptionSelected
          "
          :incorrect="
            selectedOption?.value === option.value && !correctOptionSelected
          "
          @click="onOptionSelected(option)"
        />

        <AtroContent
          v-if="showResult"
          class="flex-1 py-1"
          justify="end"
          items="center"
        >
          <AtroBadge
            class="self-end"
            :label="resultBadgeLabel"
            :type="resultBadgeType"
          />
        </AtroContent>
      </slot>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as OptionProps } from '~/components/flow/atom/FlowAtomOption.vue'

export interface Props {
  options: SelectOption[]

  correctOption?: SelectOption['value']
  hideTextOnSelection?: boolean
  modelValue?: any
  subtitle?: string
  title?: string
  type?: OptionProps['type']
}

const props = defineProps<Props>()
const emit = defineEmits<{
  optionSelected: [value: SelectOption['value']]
}>()
const model = defineModel<Props['modelValue']>()

const { correctOption, options, type = 'bubble' } = props

const selectedOption = ref(
  options.find((option) => option.value === model.value),
)

const correctOptionSelected = computed(
  () => correctOption === selectedOption.value?.value,
)
const showResult = computed(
  () => selectedOption.value && correctOption && type === 'bubble',
)
const resultBadgeLabel = computed(() =>
  correctOptionSelected.value ? 'Correct' : 'Incorrect',
)
const resultBadgeType = computed(() =>
  correctOptionSelected.value ? 'correct' : 'incorrect',
)

function onOptionSelected(option: SelectOption) {
  selectedOption.value = option
  model.value = option.value
  emit('optionSelected', option.value)
}
</script>
