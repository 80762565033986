export function useElectronApi() {
  const { isElectron } = useDevice()

  function callElectron(fn: keyof typeof window.electronAPI, ...rest: any[]) {
    if (import.meta.client && window.electronAPI) {
      try {
        // @ts-ignore
        return window.electronAPI[fn](...rest)
      } catch {
        console.error('Failed to call electronAPI', fn)
      }
    }
  }

  return {
    callElectron,
    isElectron,
  }
}
