export default function useApiPath() {
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  function _getApiPath(pathName: keyof typeof apiPaths, params?: any) {
    return getApiPath(pathName, {
      ...(org.value?.id ? { orgId: org.value.id } : {}),
      ...(user.value?.id ? { userId: user.value } : {}),
      ...params,
    })
  }

  return _getApiPath
}
