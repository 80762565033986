<template>
  <div
    v-if="model"
    id="reader"
    class="prose w-full max-w-full"
    v-html="model"
  />
  <AtroEmpty v-else class="min-h-20" />
</template>

<script setup lang="ts">
export interface Props {
  modelValue: string

  readToValidate?: boolean
}

const { readToValidate = false } = defineProps<Props>()
const model = defineModel<Props['modelValue']>()
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

onMounted(() => {
  if (readToValidate) emit('validityChange', false)
})

if (readToValidate) {
  const { pageScroll } = usePageState()
  watch(pageScroll.arrivedState, () => {
    if (pageScroll.arrivedState.bottom) emit('validityChange', true)
  })
}
</script>
