const BASE_URL = 'https://app.chatwoot.com'
const CHATWOOT_KEY = 'z9QDTf68wffgFqqTPpHntDH4'

export default defineNuxtPlugin((nuxtApp) => {
  const chatwoot = ref()

  nuxtApp.hook('app:mounted', async () => {
    const globalState = useGlobalState()
    const { isWebContext } = useClientContext()
    const { user } = useCurrentUser()
    const { org } = useCurrentOrg()
    const { plan } = useCurrentSubscription()
    const { isProduction } = useVercelBuild()
    if (!isProduction.value || !isWebContext.value) return

    const el = document.createElement('script')
    const script = document.getElementsByTagName('script')[0]
    el.src = BASE_URL + '/packs/js/sdk.js'
    el.defer = true
    el.async = true
    // @ts-ignore
    script.parentNode.insertBefore(el, script)
    el.onload = function () {
      window.chatwootSDK.run({
        websiteToken: CHATWOOT_KEY,
        baseUrl: BASE_URL,
      })
      chatwoot.value = window.chatwootSDK
    }

    function identifyUser() {
      if (user.value && window.$chatwoot) {
        window.$chatwoot.setUser(user.value.id, {
          email: user.value.email,
          name: user.value.displayName,
        })
        if (plan.value) {
          window.$chatwoot.setCustomAttributes({
            planLevel: plan.value.level || 0,
            planSlug: plan.value.slug,
            ...(globalState.value.logrocketSessionUrl
              ? { logrocketSessionUrl: globalState.value.logrocketSessionUrl }
              : {}),
            ...(org.value
              ? {
                  orgId: org.value.id,
                  orgName: org.value.name,
                  orgSlug: org.value.slug,
                  orgRole: org.value.userRole,
                }
              : {}),
          })
        }
      }
    }

    watch([globalState, user, plan], identifyUser, {
      immediate: true,
      deep: true,
    })
    window.addEventListener('chatwoot:ready', identifyUser)
  })

  return {
    provide: {
      chatwoot,
    },
  }
})
