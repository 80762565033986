export default function useTooltips() {
  const isMobile = useIsMobile()
  const { navToggleCount, view } = useHomeNav()
  const { onboardingState, isWalkthroughActive, walkthroughStatus } =
    useOnboarding()

  const hasSeenFlowAssigned = computed(
    () => onboardingState.value?.seenFlowAssigned,
  )

  const onboardingAssignedTooltip = computed(() => {
    return {
      content: 'Something is assigned to you',
      delay: { show: 1500 },
      distance: 20,
      placement: isMobile.value ? 'bottom' : 'left',
      shown:
        !hasSeenFlowAssigned.value &&
        !isWalkthroughActive.value &&
        !!navToggleCount.value &&
        view.value === 'admin',
      triggers: [],
    }
  })

  return {
    onboardingAssignedTooltip,
  }
}
