export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter()
  const { org } = useCurrentOrg()
  const { isOnboarding } = useOnboarding()
  const { clearStoredRedirectUrl, redirectAfter } = useShareUrl()

  if (import.meta.client) {
    if (!isOnboarding.value && org.value && redirectAfter.value) {
      const route = redirectAfter.value
      clearStoredRedirectUrl()
      return navigateToWithOrg(route)
    }
  } else {
    if (!redirectAfter.value && router.currentRoute.value.query.redirectAfter) {
      redirectAfter.value = router.currentRoute.value.fullPath.replace(
        'redirectAfter=true',
        '',
      )
    }
  }
})
