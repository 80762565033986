<template>
  <AtroModal size="sm" v-slot="{ close }">
    <AtroModalHeader text="Change Framework Level" />

    <AtroModalContent>
      <FormKit
        type="select"
        label="Framework Level"
        :value="currentFrameworlLevel.value"
        :options="availableFrameworkLevels"
        @input="jumpToLevel"
      />
    </AtroModalContent>

    <AtroModalActions margin="lg">
      <AtroButton block text="Done" @click="close" />
    </AtroModalActions>
  </AtroModal>
</template>
<script setup lang="ts">
const { availableFrameworkLevels, currentFrameworlLevel, jumpToLevel } =
  useFrameworkLevels()
</script>
