<template>
  <div
    :style
    ref="cardRef"
    class="fixed z-20 flex min-h-0 origin-center flex-nowrap items-center justify-center bg-white shadow-card"
  >
    <Transition
      appear
      enter-active-class="duration-1000 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <AtroContent
        v-if="view === 'close'"
        class="h-[var(--flow-card-height)] w-[var(--flow-card-width)] px-4 py-2 pt-[7px] text-center"
        direction="col"
        items="center"
      >
        <div class="mb-2.5 h-6 w-full" />
        <AtroIcon class="mb-3 size-10 text-black/45" :name="flow.icon" />
        <AtroParagraph
          semibold
          class="text-atro-slate-purple"
          size="sm-short"
          :text="flow.title"
        />
      </AtroContent>
    </Transition>

    <Transition
      appear
      enter-active-class="duration-300 delay-1000 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <AtroLoading v-if="view === 'open'" />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

export interface Props {
  cardRect: DOMRect
  flow: Flow
  view: 'open' | 'close'
  bottomToolbarActive?: boolean
}

const { bottomToolbarActive, cardRect, view } = defineProps<Props>()
const cardRef = ref(null)

const APP_BANNER_OFFSET = 48
const CONTENT_PADDING_TOP = 88
const CONTENT_PADDING_TOP_MOBILE = 84
const CONTENT_PADDING_X = 48
const CONTENT_PADDING_X_MOBILE = 8
const MAX_WIDOW_WIDTH = 1262
const SIDE_PANEL_WIDTH = 0

const isMobile = useIsMobile()
const globalState = useGlobalState()
const { endTransition } = useFlowCardTransition()
const { width: windowWidth, height: widowHeight } = useWindowSize()

const style = computed(() => {
  return {
    top: `${cardRect.top}px`,
    left: `${cardRect.left}px`,
    borderRadius: '32px',
  }
})

const appBannerOffset = computed(() =>
  globalState.value.appBannerShown ? APP_BANNER_OFFSET : 0,
)
const contentPaddingX = computed(() =>
  isMobile.value ? CONTENT_PADDING_X_MOBILE : CONTENT_PADDING_X,
)
const contentPaddingTop = computed(
  () =>
    (isMobile.value ? CONTENT_PADDING_TOP_MOBILE : CONTENT_PADDING_TOP) +
    appBannerOffset.value,
)
const contentPaddingBottom = computed(() =>
  isMobile.value
    ? bottomToolbarActive
      ? 132
      : 20
    : bottomToolbarActive
      ? 96
      : 24,
)

function getOpenedConfig() {
  const width =
    Math.min(MAX_WIDOW_WIDTH, windowWidth.value) -
    SIDE_PANEL_WIDTH -
    contentPaddingX.value * 2
  const widthOffset = Math.max(0, windowWidth.value - MAX_WIDOW_WIDTH)
  const height =
    widowHeight.value - contentPaddingTop.value - contentPaddingBottom.value
  const translateX =
    contentPaddingX.value + SIDE_PANEL_WIDTH - cardRect.left + widthOffset / 2
  const translateY = contentPaddingTop.value - cardRect.top

  return { height, width, translateX, translateY }
}

function animateCard() {
  if (!cardRef.value) return

  const isOpening = view === 'open'
  const config = getOpenedConfig()
  const sharedConfig = {
    borderRadius: '40px',
    padding: isMobile.value ? 16 : 48,
    height: config.height,
    width: config.width,
    x: config.translateX,
    y: config.translateY,
    onComplete: endTransition,
  }

  if (isOpening) {
    gsap.to(cardRef.value, {
      ...sharedConfig,
      duration: 0.7,
      ease: 'back.out(1.2)',
    })
  } else {
    gsap.from(cardRef.value, {
      ...sharedConfig,
      duration: 0.4,
      delay: 0.2,
      ease: 'ease-in',
    })
  }
}

onMounted(animateCard)
</script>
