import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45store_45redirect_45global from "/vercel/path0/middleware/01.storeRedirect.global.ts";
import authentication_45global from "/vercel/path0/middleware/authentication.global.ts";
import block_45navigation_45on_45dirty_45global from "/vercel/path0/middleware/blockNavigationOnDirty.global.ts";
import block_45navigation_45on_45missing_45org_45global from "/vercel/path0/middleware/blockNavigationOnMissingOrg.global.ts";
import free_45trial_45expired_45global from "/vercel/path0/middleware/freeTrialExpired.global.ts";
import kiosk_45mode_45global from "/vercel/path0/middleware/kioskMode.global.ts";
import mobile_45drawer_45global from "/vercel/path0/middleware/mobileDrawer.global.ts";
import onboard_45global from "/vercel/path0/middleware/onboard.global.ts";
import refresh_45session_45global from "/vercel/path0/middleware/refreshSession.global.ts";
import route_45modal_45global from "/vercel/path0/middleware/routeModal.global.ts";
import system_45admin_45global from "/vercel/path0/middleware/systemAdmin.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45store_45redirect_45global,
  authentication_45global,
  block_45navigation_45on_45dirty_45global,
  block_45navigation_45on_45missing_45org_45global,
  free_45trial_45expired_45global,
  kiosk_45mode_45global,
  mobile_45drawer_45global,
  onboard_45global,
  refresh_45session_45global,
  route_45modal_45global,
  system_45admin_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "close-flow-card-transition": () => import("/vercel/path0/middleware/closeFlowCardTransition.ts"),
  "requires-daemon": () => import("/vercel/path0/middleware/requiresDaemon.ts"),
  "requires-partner": () => import("/vercel/path0/middleware/requiresPartner.ts"),
  auth: () => import("/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_ml5ztrrc5y3o2imrcwamtp4aju/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}