<template>
  <AtroBadge class="shrink-0" v-bind="props" :label="text" />
</template>

<script setup lang="ts">
import type { BadgeProps } from '@atro/components'

export interface Props {
  text: string

  bordered?: BadgeProps['bordered']
  capitalize?: BadgeProps['capitalize']
  color?: BadgeProps['color']
  rounded?: BadgeProps['rounded']
  size?: BadgeProps['size']
  truncate?: BadgeProps['truncate']
  type?: BadgeProps['type']
}
const props = defineProps<Props>()
</script>
