<template>
  <AtroContent
    class="w-full space-x-6 overflow-auto py-2 sm:w-auto"
    wrap="nowrap"
    items="center"
    justify="center"
  >
    <div
      v-for="anchor in anchors"
      :class="[
        'whitespace-nowrap',
        {
          'text-primary': activeAnchor === anchor,
          'cursor-pointer text-atro-gray': activeAnchor !== anchor,
        },
      ]"
      :key="anchor"
      @click="jumpToAnchor(anchor)"
    >
      <AtroSpan :semibold="activeAnchor === anchor" :text="anchor" />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  anchors: string[]
}

const { anchors } = defineProps<Props>()

const activeAnchor = ref(anchors[0])

function jumpToAnchor(anchor: string) {
  activeAnchor.value = anchor
}
</script>
