<template>
  <AtroGridTable gap="sm" :columns="columnsConfig" :data="userPhishingReports">
    <template #default="{ columnProps, items }">
      <TeamPhishingReportTableRow
        v-for="userPhishingReport in items"
        v-bind="columnProps"
        :user-phishing-report
        :key="userPhishingReport.user.id"
        @send-test="(userId: string) => $emit('sendTest', userId)"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
export interface Props {
  userPhishingReports: UserPhishingReport[]
}

defineProps<Props>()
defineEmits<{
  sendTest: [userId: string]
}>()

const columnsConfig = computed<TableColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Assigned Users',
    },
    fixedWidth: true,
    minColumnWidth: 180,
    sortBy: ['user.displayName', 'user.email'],
  },
  {
    heading: {
      align: 'center',
      text: 'Current Status',
    },
    fixedWidth: true,
    minColumnWidth: 130,
  },
  {
    heading: {
      align: 'center',
      text: 'Emails Received',
    },
    fixedWidth: true,
    minColumnWidth: 112,
    sortBy: 'emailsReceivedCount',
  },
  {
    heading: {
      align: 'center',
      text: 'Phished',
    },
    fixedWidth: true,
    minColumnWidth: 63,
    sortBy: 'phishedCount',
    initialSortedBy: 'desc',
  },
  {
    heading: {
      align: 'center',
      text: 'Credentials Input',
    },
    fixedWidth: true,
    minColumnWidth: 116,
    sortBy: 'credentialsInputCount',
  },
  {
    heading: {
      align: 'center',
      text: 'Last Trained',
    },
    fixedWidth: true,
    minColumnWidth: 74,
  },
  {
    actionColumn: true,
    actionColumnShrink: true,
  },
])
</script>
