export default function useHomeNav() {
  const viewTransitioning = useState('homeViewTransitioning', () => false)

  const route = useRoute()
  const { rootPath } = useCurrentOrg()
  const { isAdminOrOwner } = useCurrentUser()
  const { setOnboardingState } = useOnboarding()

  const orgFlows = useData<OrgFlowsResponse>('orgFlows', {
    options: { cached: true },
  })
  const userFlows = useData<OrgFlow[]>('userFlows', {
    options: { cached: true },
  })

  const view = computed(() => {
    const _view = route.query.view as View
    if (isAdminOrOwner.value) {
      return _view || 'admin'
    } else {
      return 'member'
    }
  })
  const isAdminView = computed(() => view.value === 'admin')
  const isMemberView = computed(() => view.value === 'member')
  const orgFlowCount = computed(() => orgFlows?.data.value?.orgFlows.length)

  const navToggleCount = computed(() => {
    switch (view.value) {
      case 'admin':
        return (
          userFlows?.data.value?.filter(
            (userFlow) => userFlow.status === 'warning',
          ).length || 0
        )
      case 'member':
        return (
          orgFlows?.data.value?.orgFlows.filter(
            (orgFlow) => orgFlow.status === 'warning',
          ).length || 0
        )
      default:
        return 0
    }
  })

  const transitioningText = computed(() =>
    view.value === 'admin' ? 'Admin console' : 'Assigned to you',
  )

  const toggleView = async () => {
    viewTransitioning.value = true
    if (isAdminView.value) {
      refreshData('userFlows')
      navigateTo({ path: rootPath.value, query: { view: 'member' } })
    } else {
      refreshData('orgFlows')
      navigateTo(rootPath.value)
    }
    setOnboardingState('seenFlowAssigned', true)
  }

  const goBackToDashboard = () => {
    if (isAdminOrOwner.value && route.path.includes('user-flows')) {
      navigateTo({ path: rootPath.value, query: { view: 'member' } })
    } else {
      navigateTo(rootPath.value)
    }
  }

  return {
    isAdminView,
    isMemberView,
    navToggleCount,
    orgFlows,
    orgFlowCount,
    transitioningText,
    view,
    viewTransitioning,

    goBackToDashboard,
    toggleView,
  }
}
