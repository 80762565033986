import type { FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
} from '@formkit/inputs'

export const maskInput = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        icon('prefix', 'label'),
        prefix(),
        textInput(),
        suffix(),
        icon('suffix'),
      ),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['mask'],
  /**
   * Additional features that should be added to your input
   */
  features: [initializeMask],
}

function initializeMask(node: FormKitNode) {
  node.on('created', async () => {
    if (import.meta.client) {
      const Inputmask = (await import('inputmask')).default
      const inputEl = document.querySelector('input#' + node.props.id)
      if (inputEl && node.props.mask)
        Inputmask(node.props.mask).mask(inputEl as HTMLElement)
    }
  })
}
