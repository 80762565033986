<template>
  <AtroContent class="w-full space-y-2" direction="col">
    <div>
      <AtroSpan v-if="title" class="text-atro-gray" :text="title" />
      <span v-if="required" class="field-is-required">*</span>
    </div>
    <AtroContent
      :class="[
        'w-full',
        { 'gap-2': gap === 'sm', 'gap-3': gap === 'md', 'gap-4': gap === 'lg' },
      ]"
    >
      <FlowComponent
        v-for="(config, i) in components"
        :config
        :data
        :deps
        :key="config.name"
        @action="onAction"
        @validity-change="(validity) => onValidityChange(validity, i)"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  components: FlowComponentConfig[]

  data?: Record<string, any>
  deps?: Record<string, any>

  gap?: 'sm' | 'md' | 'lg'
  required?: boolean
  title?: string
}

const { components, required, gap = 'md' } = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()

const validityObj = ref(
  components.reduce(
    (validObj, comp, i) => {
      validObj[i] = false
      return validObj
    },
    {} as Record<number, boolean>,
  ),
)

const onAction = (action: FlowAction) => {
  emit('action', action)
}
function onValidityChange(valid: boolean, index: number) {
  validityObj.value[index] = valid
}

watchDeep(
  validityObj,
  () => {
    required &&
      emit(
        'validityChange',
        Object.values(validityObj.value).every((item) => item),
      )
  },
  { immediate: true },
)
</script>
