import { format } from 'date-fns'

export function formatDate(isoString: string, formatString = 'MM.dd.yyyy') {
  try {
    const date = new Date(isoString)
    return format(date, formatString)
  } catch (e) {
    return ''
  }
}
