export function usePaywall() {
  const { openModal } = useModal()

  async function triggerPaywall(
    onThroughPaywall: () => any = () => true,
    onPaywallClose?: () => void,
  ) {
    openModal('subscriptionUpgrade', {
      props: {
        onCloseWithoutUpgrade: onPaywallClose,
        onUpgradeConfirm: onThroughPaywall,
      },
    })
  }

  return { triggerPaywall }
}
