export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return
  const { currentModal, closeModal, openModal } = useModal()
  const { user } = useCurrentUser()

  if (!user.value) return

  if (to.query.modal) {
    let options
    if (to.query.options) {
      try {
        options = JSON.parse(decodeURIComponent(to.query.options as string))
      } catch {}
    }
    openModal(to.query.modal as any, options as any)
  } else if (currentModal.value?.isOpen) {
    closeModal()
    if (to.path.includes('flow')) {
      setTimeout(closeModal, 100)
    } else {
      closeModal()
    }
  }
})
