<template>
  <AtroContent
    class="h-56 w-full space-y-2 sm:space-x-1 sm:space-y-0"
    wrap="nowrap"
    :direction="$isMobile.value ? 'col' : 'row'"
  >
    <AtroContent
      v-for="(dataPoint, i) in dataPoints"
      :key="`${dataPoint.key}-${dataPoint.count}-${i}`"
      class="group h-full w-full flex-1 text-atro-gray"
      direction="col"
      items="center"
      wrap="nowrap"
    >
      <AtroContent
        shrink
        class="h-full w-full"
        items="center"
        wrap="nowrap"
        :direction="$isMobile.value ? 'row' : 'col'"
        :justify="$isMobile.value ? 'start' : 'end'"
      >
        <AtroContent
          class="w-20 max-w-48 sm:w-full"
          direction="col"
          :items="$isMobile.value ? 'start' : 'center'"
        >
          <AtroSpan v-if="$isMobile.value" class="w-full pt-2" size="sm">{{
            dataPoint.label
          }}</AtroSpan>
          <AtroHeading class="mb-4" size="sm"
            ><AtroCounter :to="dataPoint.count" :delay="0.66" :duration="1"
          /></AtroHeading>
        </AtroContent>
        <div
          :class="[
            'data-bar h-full rounded-br-lg sm:h-auto sm:w-full sm:rounded-br-none sm:rounded-tr-lg',
            {
              'rounded-tl-none rounded-tr-lg sm:rounded-tl-lg': i === 0,
              'bg-atro-blue': dataPoint.color === 'blue',
              'bg-atro-green': dataPoint.color === 'green',
              'bg-atro-yellow': dataPoint.color === 'yellow',
              'bg-atro-red/75': dataPoint.color === 'pink',
              'bg-atro-red': dataPoint.color === 'red',
            },
          ]"
        />
      </AtroContent>
      <AtroContent
        v-if="!$isMobile.value"
        class="mt-2 space-x-2"
        items="center"
        justify="center"
        wrap="nowrap"
      >
        <AtroIcon
          v-if="dataPoint.labelIcon"
          class="size-4"
          :name="dataPoint.labelIcon"
        />
        <AtroSpan size="xs" :text="dataPoint.label" />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import gsap from 'gsap'

type DataPoint = {
  color: 'blue' | 'green' | 'yellow' | 'pink' | 'red'
  count: number
  key: string
  label: string

  labelIcon?: IconName
}

export interface Props {
  dataPoints: DataPoint[]
}

const props = defineProps<Props>()

const isMobile = useIsMobile()

const maxCount = computed(
  () =>
    props.dataPoints
      .map((dataPoint) => dataPoint.count)
      .sort((a, b) => b - a)[0] || 1,
)

function animate() {
  nextTick(() => {
    const divs = document.querySelectorAll('div.data-bar')
    divs.forEach((div, i) => {
      const dataPoint = props.dataPoints[i]
      const size = Math.max(
        Math.ceil((dataPoint.count / maxCount.value) * 100),
        1,
      )
      console.log(div, 'dataPoint.count', dataPoint.count, 'size', size)

      gsap.fromTo(
        div,
        {
          ...(isMobile.value ? { width: '1%' } : { height: '1%' }),
        },
        {
          ...(isMobile.value ? { width: `${size}%` } : { height: `${size}%` }),
          delay: 0.66,
          duration: 0.75,
          ease: 'ease-in-out',
        },
      )
    })
  })
}

watch([isMobile, () => props.dataPoints], animate, { deep: true })
onMounted(animate)
</script>
