<template>
  <component
    v-if="isShown"
    v-model="model"
    v-bind="componentProps"
    :data-type="config.type"
    :is="Component"
  />
</template>

<script setup lang="ts">
import dot from 'dot-object'
import { toCamelCase } from 'js-convert-case'

export interface Props {
  config: {
    name: string
    type: string

    dataMappings?: string[][]
    modelValue?: string
    props?: any
  }

  data?: Record<string, any>
  deps?: Record<string, any>
}

const props = defineProps<Props>()

const config = computed(() => props.config)
const Component = useComponentForFlow(config.value.type)

const isWritable = ref(true)

const linkedDataMappings = computed(() => {
  return Array.isArray(config.value.dataMappings)
    ? config.value.dataMappings.reduce(
        (mappings, current) => {
          mappings[current[1]] = dot.pick(current[0], props)
          return mappings
        },
        {} as Record<string, any>,
      )
    : {}
})
const componentProps = computed(() => {
  const src = {
    ...config.value.props,
    data: props.data,
    deps: props.deps,
  }
  // INJECT DATA MAPPINGS
  Object.entries(linkedDataMappings.value).forEach(([key, val]) => {
    dot.set(key, val, src)
  })
  return src
})
const dataKey = computed(() => toCamelCase(config.value.modelValue || ''))
const isShown = computed(() => {
  if (Array.isArray(componentProps.value?.show)) {
    return componentProps.value?.show.every(
      (showPropName: string) =>
        typeof showPropName === 'string' && dot.pick(showPropName, props),
    )
  }
  if (typeof componentProps.value?.show === 'string') {
    return dot.pick(componentProps.value.show, props)
  } else if (typeof componentProps.value?.show === 'boolean') {
    return componentProps.value?.show
  } else {
    return true
  }
})
const model = computed({
  get() {
    return dataKey.value
      ? props.data?.[dataKey.value]
      : componentProps.value.modelValue
  },
  set(value) {
    if (props.data && dataKey.value && isWritable.value) {
      props.data[dataKey.value] = value
    }
  },
})

// we have to set isWritable as the component is unmounting
// so we don't clobber model state with undefined values
onBeforeUnmount(() => {
  isWritable.value = false
})
</script>
