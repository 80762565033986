<template>
  <AtroContent
    shrink
    :class="[
      'group hover:text-primary',
      { 'cursor-pointer': profileModalEnabled },
    ]"
    items="center"
    wrap="nowrap"
    @click="
      (e) => {
        if (profileModalEnabled) {
          e.stopPropagation()
          openModal('userProfile', { props: { user } })
        }
      }
    "
  >
    <UserAvatar
      v-if="!hideAvatar"
      :user
      :size="size === 'sm' && $isMobile.value ? 'md' : size"
    />
    <AtroContent
      shrink
      wrap="nowrap"
      :class="[
        'w-full flex-1',
        {
          'ml-2': size === 'sm' || size === 'md',
          'ml-3': size === 'lg',
          'ml-6': size === 'xl',
          'gap-2': isRow,
          'gap-1': !isRow && ['xl', 'lg'].includes(size),
        },
      ]"
      :direction="isRow ? 'row' : 'col'"
      :items="isRow ? 'center' : 'start'"
    >
      <AtroContent
        shrink
        wrap="nowrap"
        :class="[{ 'gap-2': size === 'sm' && !$isMobile.value }]"
        :direction="size === 'sm' && !$isMobile.value ? 'row' : 'col'"
      >
        <div class="relative">
          <AtroSpan
            :size
            :class="[
              'w-full transition-all sm:w-auto',
              {
                truncate: truncated,
                'inline-block group-hover:-translate-y-2.5':
                  size !== 'sm' && showEmailOnHover,
              },
            ]"
            :semibold="size !== 'sm'"
            :text="name"
          />
          <AtroBadge
            v-if="showInvitedBadge && !user.joined"
            :class="[
              'absolute top-1/2 ml-2 -translate-y-1/2',
              {
                'group-hover:opacity-0': showEmailOnHover,
              },
            ]"
            label="Invited"
            size="sm"
          />
        </div>

        <AtroSpan
          v-if="email && !$isMobile.value"
          :size="size === 'sm' ? 'sm' : 'md'"
          :class="[
            'w-full text-atro-gray transition-all sm:w-auto',
            {
              truncate: truncated,
              'opacity-0 group-hover:opacity-100': showEmailOnHover,
              'absolute inline-block group-hover:translate-y-2.5':
                size !== 'sm' && showEmailOnHover,
            },
          ]"
          :text="email"
        />
      </AtroContent>

      <slot />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  user: User
  profileModalEnabled?: boolean
  hideAvatarWhenMobile?: boolean
  isRow?: boolean
  showEmail?: boolean
  showEmailOnHover?: boolean
  showInvitedBadge?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  truncated?: boolean
}

const {
  showEmail,
  showEmailOnHover,
  user,
  hideAvatarWhenMobile = false,
  isRow = true,
  profileModalEnabled = true,
  size = 'md',
  showInvitedBadge = true,
  truncated = true,
} = defineProps<Props>()

const isMobile = useIsMobile()
const { openModal } = useModal()

const hideAvatar = computed(() => isMobile.value && hideAvatarWhenMobile)
const name = computed(() => user.displayName || user.email)
const email = computed(() =>
  (showEmail || showEmailOnHover) && user.displayName ? user.email : '',
)
</script>
