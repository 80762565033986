import revive_payload_client_7c81llWv3E from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_aB2d0G4sth from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gPRFp4OtAD from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_d6baMH3n1Q from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_terser@5._w7hl5sa2k3yh5iegl6d37ovuzy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_LxbF2KOOfR from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oqubATzhw0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CrU9zb2UvX from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AKjzRFyejG from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_3fpKewdGLN from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mvv6dngrahkx623i2qxoigi64a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8rdNPMW9vp from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_ml5ztrrc5y3o2imrcwamtp4aju/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import nuxt_plugin_bVcOgAfXRB from "/vercel/path0/node_modules/.pnpm/vue-recaptcha@3.0.0-alpha.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import apex_charts_client_nU3LNFyvBu from "/vercel/path0/plugins/apex-charts.client.ts";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import auto_expand_client_XgXhwPISRQ from "/vercel/path0/plugins/auto-expand.client.ts";
import chatwoot_crLW9YLG1U from "/vercel/path0/plugins/chatwoot.ts";
import electron_device_rFvu8uW7qu from "/vercel/path0/plugins/electron-device.ts";
import floating_vue_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import mobile_OriQq9jmDY from "/vercel/path0/plugins/mobile.ts";
import scroller_iZCcO5r4hO from "/vercel/path0/plugins/scroller.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import sonner_KD25tq4EBi from "/vercel/path0/plugins/sonner.ts";
import trackers_FpmZMuzECg from "/vercel/path0/plugins/trackers.ts";
export default [
  revive_payload_client_7c81llWv3E,
  unhead_aB2d0G4sth,
  router_gPRFp4OtAD,
  _0_siteConfig_d6baMH3n1Q,
  payload_client_LxbF2KOOfR,
  navigation_repaint_client_oqubATzhw0,
  check_outdated_build_client_CrU9zb2UvX,
  chunk_reload_client_AKjzRFyejG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3fpKewdGLN,
  plugin_8rdNPMW9vp,
  plugin_wy0B721ODc,
  formkitPlugin_pZqjah0RUG,
  nuxt_plugin_bVcOgAfXRB,
  apex_charts_client_nU3LNFyvBu,
  auto_animate_uTpJuTGsMC,
  auto_expand_client_XgXhwPISRQ,
  chatwoot_crLW9YLG1U,
  electron_device_rFvu8uW7qu,
  floating_vue_BiOD74u9sH,
  mobile_OriQq9jmDY,
  scroller_iZCcO5r4hO,
  sentry_3AyO8nEfhE,
  sonner_KD25tq4EBi,
  trackers_FpmZMuzECg
]