<template>
  <button
    v-auto-animate
    :class="[
      'transition-all focus-visible:ring-2 disabled:opacity-50',
      {
        'rounded-[48px] px-4 py-2 font-semibold': type === 'bubble',
        'text-md rounded-lg border-2 border-transparent p-2 text-left text-atro-gray-2':
          type === 'text',
        'bg-atro-green-15-15 border-atro-green bg-atro-green/10':
          type === 'text' && correct,
        'border-atro-red bg-atro-red-15':
          type === 'text' && hasCorrectOption && incorrect,
        'hover:bg-atro-purple/5 hover:text-atro-purple':
          type === 'text' && !incorrect && !correct,
        'bg-atro-purple/5 text-atro-purple':
          type === 'text' && !hasCorrectOption && active,
        'bg-atro-purple text-white ring-inset ring-white enabled:hover:bg-atro-purple-75':
          active && type === 'bubble',
        'bg-white text-atro-purple ring-atro-purple enabled:hover:bg-atro-purple/25':
          !active && type === 'bubble',
      },
    ]"
  >
    <AtroContent :class="['w-full']" wrap="nowrap">
      <AtroIcon
        v-if="type === 'text'"
        :class="[
          'mr-2 mt-1 h-4 w-4',
          {
            'text-atro-gray-2': !incorrect && !correct,
            'text-atro-green': hasCorrectOption && correct,
            'text-atro-red': hasCorrectOption && incorrect,
          },
        ]"
        :name="radioIcon"
      />
      <AtroMarkdown :markdown="option.label" />
    </AtroContent>

    <AtroParagraph
      v-if="active && hasCorrectOption && type === 'text'"
      :class="[
        'ml-6 mt-2',
        {
          'text-atro-gray-2': !incorrect && !correct,
          'text-atro-green': correct,
          'text-atro-red': incorrect,
        },
      ]"
      :text="resultsText"
    />
  </button>
</template>

<script setup lang="ts">
export interface Props {
  option: SelectOption

  active?: boolean
  correct?: boolean
  hasCorrectOption?: boolean
  incorrect?: boolean
  type?: 'bubble' | 'text'
}

const { active, correct, hasCorrectOption, incorrect, option } =
  defineProps<Props>()

const radioIcon = computed<IconName>(() => {
  if (hasCorrectOption) {
    if (correct) {
      return 'circle-check-outline'
    } else if (incorrect) {
      return 'circle-xmark-outline'
    }
  } else if (active) {
    return 'circle-check-outline'
  }
  return 'circle'
})

const resultsText = computed(() => {
  if (correct) {
    return option.answeredText || 'Correct!'
  } else {
    return option.answeredText || 'Try again!'
  }
})
</script>
