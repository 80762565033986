<template>
  <div class="html-atom pointer-events-none w-full" v-html="model" />
</template>

<script setup lang="ts">
export interface Props {
  modelValue: string
}

defineProps<Props>()
const model = defineModel<Props['modelValue']>()
</script>

<style scoped>
.html-atom * {
  all: revert;
}
</style>
