<template>
  <AtroContent
    :class="['relative w-fit', { 'origin-top scale-150': size === 'lg' }]"
    direction="col"
    items="center"
  >
    <svg
      width="184"
      height="92"
      viewBox="0 0 184 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Base gauge paths -->
      <mask
        id="gauge-mask"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="184"
        height="93"
      >
        <path
          d="M122.066 35.4616C121.203 36.9563 119.322 37.5004 117.75 36.7874C110.086 33.3109 101.575 31.3751 92.6117 31.3751C83.3564 31.3751 74.5832 33.4391 66.7273 37.132C65.1492 37.8739 63.2401 37.3363 62.3682 35.8262L50.0227 14.443C48.9962 12.6651 49.6296 10.384 51.4689 9.47187C63.7008 3.40604 77.4833 -0.00390625 92.0622 -0.00390625C106.673 -0.00390625 120.484 3.42101 132.736 9.51182C134.573 10.4251 135.205 12.7047 134.179 14.4815L122.066 35.4616Z"
          fill="#D9D9D9"
        />
        <path
          d="M156.713 91.4867C154.945 91.4867 153.514 90.0624 153.399 88.2976C152.159 69.1292 142.052 52.3764 127.139 42.0981C125.699 41.1055 125.203 39.1769 126.078 37.6621L138.118 16.8081C139.149 15.022 141.457 14.4348 143.167 15.5882C166.585 31.3878 182.297 57.7407 183.481 87.8262C183.56 89.8458 181.914 91.4867 179.893 91.4867H156.713Z"
          fill="#D9D9D9"
        />
        <path
          d="M58.4334 38.1599C59.299 39.6593 58.8233 41.5674 57.411 42.569C42.8674 52.8838 33.0459 69.4192 31.8241 88.2977C31.7099 90.0625 30.2789 91.4867 28.5104 91.4867H4.23112C2.20997 91.4867 0.563921 89.8458 0.643386 87.8262C1.82842 57.7088 17.5722 31.3319 41.0323 15.538C42.7421 14.387 45.0475 14.9748 46.078 16.7598L58.4334 38.1599Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#gauge-mask)">
        <!-- Colored sections with transitions -->
        <path
          :style="sectionStyles.red"
          d="M75.92 65.7448L41.3455 3.20312L-1.57456 39.3473L-11.5098 95.3911L53.2678 97.4217L75.92 65.7448Z"
          fill="#ED7C7C"
          stroke="#ED7C7C"
          stroke-width="0.410246"
        />
        <path
          d="M110.003 58.5484L145.852 -4.44228L91.8162 -25.7466L38.0183 -6.83961L70.5713 53.6049L110.003 58.5484Z"
          fill="#FFB016"
          :style="sectionStyles.yellow"
        />
        <path
          d="M127.673 94.6542L200.145 93.7261L191.198 36.3355L147.68 -0.514442L111.997 58.1366L127.673 94.6542Z"
          fill="#64B685"
          :style="sectionStyles.green"
        />
      </g>

      <!-- Custom gauge pointer with smooth transition -->
      <g :style="pointerStyle">
        <!-- Custom pointer shape -->
        <path
          d="M44.0679 3.99613C44.0679 1.77644 42.1779 0.0267193 39.9648 0.197518L8.51512 2.62469C3.31578 3.02595 0.716106 3.22658 0.515074 3.64883C0.410465 3.86855 0.410465 4.12371 0.515074 4.34343C0.716106 4.76568 3.31577 4.96631 8.51511 5.36757L39.9648 7.79474C42.1779 7.96554 44.0679 6.21582 44.0679 3.99613V3.99613Z"
          fill="#353466"
        />
      </g>
    </svg>

    <template v-if="!hideLabels">
      <AtroHeading
        semibold
        :class="['!leading-none']"
        :style="{ color: activeColor }"
        size="xl"
        :text="getPostureText(score)"
      />
      <AtroSpan
        semibold
        class="!leading-normal text-atro-slate-purple"
        size="sm"
        :text="score.toString()"
      />
    </template>
  </AtroContent>
</template>

<script setup lang="ts">
import { getPostureText } from '@atro/components'
// Type definitions
type TimingFunction =
  | 'linear'
  | 'ease'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | `cubic-bezier(${number}, ${number}, ${number}, ${number})`

interface StyleObject {
  opacity?: number
  transform?: string
  transformOrigin?: string
  transition?: string
}

interface SectionStyles {
  red: StyleObject
  yellow: StyleObject
  green: StyleObject
}

interface Props {
  score: number
  hideLabels?: boolean
  size?: 'md' | 'lg'
  transitionDuration?: number
  transitionTiming?: TimingFunction
}

const {
  score,
  size = 'md',
  transitionDuration = 0.75,
  transitionTiming = 'ease-in-out',
} = defineProps<Props>()

defineEmits<{
  (e: 'update:percentage', value: number): void
  (e: 'threshold-crossed', value: 'red' | 'yellow' | 'green'): void
}>()

const percentage = computed(() => Math.floor((score / 1000) * 100))

// Internal percentage state that starts at 0
const internalPercentage = ref(0)

// Update internal percentage after mount
onMounted(() => {
  // Use nextTick to ensure the initial 0 state is rendered first
  nextTick(() => {
    setTimeout(() => (internalPercentage.value = percentage.value), 200)
  })
})

// Watch for prop changes after initial animation
watch(percentage, (newValue) => {
  internalPercentage.value = newValue
})

const pointerRotation = computed<number>(() => {
  return internalPercentage.value * 1.8
})

const pointerStyle = computed<StyleObject>(() => {
  return {
    transform: `rotate(${pointerRotation.value}deg) translate(52px, 78px)`,
    transformOrigin: '92px 82px',
    transition: `transform ${transitionDuration}s ${transitionTiming}`,
  }
})

const transitionStyle = computed<StyleObject>(() => ({
  transition: `opacity ${transitionDuration}s ease-in-out`,
}))

const sectionStyles = computed<SectionStyles>(() => ({
  red: {
    ...transitionStyle.value,
    opacity: internalPercentage.value <= 33 ? 1 : 0.1,
  },
  yellow: {
    ...transitionStyle.value,
    opacity:
      internalPercentage.value > 33 && internalPercentage.value <= 66 ? 1 : 0.1,
  },
  green: {
    ...transitionStyle.value,
    opacity: internalPercentage.value > 66 ? 1 : 0.1,
  },
}))

const activeColor = computed(() => {
  if (internalPercentage.value <= 33) return '#ED7C7C'
  if (internalPercentage.value <= 66) return '#FFB016'
  return '#64B685'
})
</script>
