<template>
  <AtroContent class="w-full gap-1">
    <div
      v-for="(riskPill, i) in riskPills"
      :key="riskPill"
      :class="[
        'relative flex h-2 flex-1 rounded-full transition-colors',
        riskIndex >= i ? getRiskColor(risk, 'bg') : 'bg-atro-warm-gray',
      ]"
    >
      <div
        v-if="riskPill === risk"
        :class="[
          'absolute -top-0.5 right-0 size-3 rounded-full border-4 bg-white',
          getRiskColor(risk, 'border'),
        ]"
      />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
import { getRiskColor } from '@atro/components'

export interface Props {
  risk: RiskLevel
}

const { risk } = defineProps<Props>()

const riskPills = ['low', 'medium', 'high']

const riskIndex = computed(() =>
  riskPills.findIndex((riskPill) => riskPill === risk),
)
</script>
