<template>
  <AtroContent v-auto-animate class="w-full gap-10" direction="col">
    <!-- QUESTION SET PROGRESS -->
    <AtroCard
      v-if="!isComplete"
      :class="['space-y-4', { '!bg-atro-green-15': isComplete }]"
      color="gray2"
    >
      <AtroContent class="w-full gap-6" justify="between">
        <AtroContent class="mb-2.5 gap-3" direction="col">
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            :text="setTitle"
          />
          <AtroSpan
            class="text-atro-gray"
            size="lg"
            :text="`Get ${requiredCorrectCount} questions correct to complete this lesson`"
          />
        </AtroContent>

        <AtroContent class="gap-3 self-end" items="center">
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            size="xxl"
            :text="`${correctAnswerCount} of ${requiredCorrectCount}`"
          />
          <AtroIcon class="size-8 text-atro-green" name="circle-check" />
        </AtroContent>
      </AtroContent>

      <div class="h-2 w-full rounded-full bg-atro-blue-15">
        <div
          :class="[
            'h-full rounded-full transition-all',
            { 'bg-atro-green': isComplete, 'bg-atro-blue': !isComplete },
          ]"
          :style="{ width: `${percentComplete}%` }"
        />
      </div>
    </AtroCard>

    <!-- QUESTION AND OPTIONS -->
    <FlowAtomOptionsGroup
      v-if="currentQuestion && !isComplete"
      v-auto-animate
      v-bind="currentQuestion"
      v-model="answer"
      hide-text-on-selection
      :key="currentQuestion.id"
      type="text"
      @option-selected="onAnswer"
    >
      <!-- ANSWER CONTAINER -->
      <AtroCard
        v-if="answer"
        bordered
        color="gray2"
        :class="[
          'items-center space-y-8 rounded-4xl',
          {
            'border-atro-green': isAnswerCorrect,
            'border-atro-red': !isAnswerCorrect,
          },
        ]"
      >
        <AtroContent
          :class="[
            'w-full gap-2 text-center',
            {
              'text-atro-green': isAnswerCorrect,
              'text-atro-red': !isAnswerCorrect,
            },
          ]"
          direction="col"
          items="center"
          justify="center"
        >
          <AtroIcon
            class="size-12"
            :name="isAnswerCorrect ? 'circle-check' : 'circle-xmark'"
          />
          <AtroHeading
            semibold
            size="xxl"
            :text="isAnswerCorrect ? 'Correct!' : 'Incorrect'"
          />
          <AtroParagraph size="lg" :text="selectedOption?.answeredText" />
        </AtroContent>

        <AtroContent
          class="w-full text-center text-atro-gray-2"
          direction="col"
          items="center"
          justify="center"
        >
          <AtroHeading semibold size="xs" text="Your answer was:" />
          <AtroParagraph :text="selectedOption?.label" />
        </AtroContent>

        <AtroButton
          v-if="!isComplete"
          text="Next Question"
          type="light"
          @click="nextQuestion"
        />
      </AtroCard>
    </FlowAtomOptionsGroup>

    <!-- COMPLETED CARD -->
    <AtroCard
      v-if="isComplete"
      bordered
      :class="['items-center gap-8 rounded-4xl !bg-atro-green-15 sm:!p-20']"
    >
      <AtroContent
        :class="['w-full gap-2 text-center']"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroIconWrap
          circle
          class="!bg-atro-green !text-white"
          name="check"
          size="sm"
        />
        <AtroHeading
          semibold
          class="text-atro-slate-purple"
          size="xl"
          :text="`${setTitle} Completed!`"
        />
        <AtroHeading
          semibold
          class="text-atro-green"
          size="md"
          :text="`${requiredCorrectCount} of ${requiredCorrectCount} questions correct`"
        />
      </AtroContent>

      <AtroContent
        class="w-full text-center text-atro-gray-2"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroParagraph
          class="text-atro-slate-purple"
          size="lg"
          text="Continue to take the next step toward completing your training"
        />
      </AtroContent>

      <AtroButton
        v-if="!isComplete"
        text="Next Question"
        type="light"
        @click="nextQuestion"
      />
    </AtroCard>
  </AtroContent>
</template>

<script setup lang="ts">
type Question = {
  correctOption: SelectOption['value']
  id: string
  options: SelectOption[]
  subtitle: string
  title: string

  correctText?: string
  incorrectText?: string
}

type QuestionSetModel = Record<string, SelectOption['value']>

export interface Props {
  currentQuestionIndex: number
  modelValue: Record<string, QuestionSetModel[]>
  questions: Question[]
  requiredCorrectCount: number
  setTitle: string
  slug: string
}

const {
  slug,
  questions,
  requiredCorrectCount,
  currentQuestionIndex = 0,
} = defineProps<Props>()
const _model = defineModel<Props['modelValue']>({ default: {} })
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

if (!_model.value?.[slug]) {
  _model.value[slug] = []
}

const model = ref(_model.value[slug])
const questionIndex = ref(currentQuestionIndex)
const currentQuestion = computed(() => questions[questionIndex.value])

// Initialize answer ref with existing answer if it exists
const answer = ref(
  (() => {
    if (!currentQuestion.value) return null
    const existingAnswer = model.value.find(
      (a) => Object.keys(a)[0] === currentQuestion.value.id,
    )
    return existingAnswer ? Object.values(existingAnswer)[0] : null
  })(),
)

const isAnswerCorrect = computed(
  () => answer.value === currentQuestion.value?.correctOption,
)
const isComplete = computed(
  () => correctAnswerCount.value >= requiredCorrectCount,
)
const correctAnswerCount = computed(() =>
  model.value.reduce((count, answer) => {
    const [key, value] = Object.entries(answer)[0]
    if (questions.find((q) => q.id === key)?.correctOption === value) count += 1
    return count
  }, 0),
)
const selectedOption = computed(() =>
  currentQuestion.value?.options.find(
    (option) => option.value === answer.value,
  ),
)
const percentComplete = computed(() =>
  Math.floor((correctAnswerCount.value / requiredCorrectCount) * 100),
)

function nextQuestion() {
  answer.value = null
  if (questions.length > questionIndex.value + 1) questionIndex.value += 1
}
function onAnswer(value: SelectOption['value']) {
  model.value.push({ [currentQuestion.value.id]: value })
}

watchEffect(() => {
  emit('validityChange', isComplete.value)
})
</script>
