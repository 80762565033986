<template>
  <AtroContent
    direction="col"
    items="center"
    :class="[
      'group max-w-[100px] space-y-2 text-center',
      {
        'cursor-pointer': isClickable,
      },
    ]"
    :tabbable="isClickable"
    @click="onAction"
  >
    <AtroGauge
      v-if="type === 'gauge'"
      :color
      :class="{
        'rounded-full ring-offset-2 transition-all group-hover:opacity-50 group-focus-visible:ring-2 group-active:opacity-100':
          isClickable,
      }"
      :animation-delay="0.66"
      :fill-percent="gaugeFillpercent"
      :icon="gaugeIcon"
    />
    <AtroContent v-if="type == 'warning'" class="relative">
      <AtroIcon
        :class="[
          'h-16 w-16',
          {
            'text-atro-red': count,
            'text-atro-warm-gray': !count,
            'transition-opacity group-hover:opacity-50 group-active:opacity-100':
              isClickable,
          },
        ]"
        name="triangle"
      />
      <AtroSpan
        semibold
        :class="[
          'absolute left-1/2 top-1/2 mt-1.5 -translate-x-1/2 -translate-y-1/2 !text-3xl text-white',
          { '!mt-2.5': Number(count) >= 10 && Number(count) < 100 },
        ]"
        >{{ Number(count) < 100 ? count : '!' }}</AtroSpan
      >
    </AtroContent>
    <AtroSpan semibold :text class="text-atro-gray" size="sm" />
  </AtroContent>
</template>

<script setup lang="ts">
import type { GaugeProps } from '@atro/components'

export interface Props {
  text: string
  type: 'gauge' | 'warning'

  action?: FlowActionType
  actionTarget?: string
  color?: GaugeProps['color']
  count?: string | number
  gaugeFillpercent?: number
  gaugeIcon?: IconName
}

const { action, actionTarget } = defineProps<Props>()

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const isClickable = computed(() => !!action)

function onAction() {
   if (action) emit('action', { type: action, data: actionTarget })
}
</script>
