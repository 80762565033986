export default function useAction(action?: Action) {
  const { openModal } = useModal()

  const actionStatusIcon = computed<IconName>(() => {
    switch (action?.status) {
      case 'active':
        return 'check'
      case 'completed':
        return 'check'
      case 'ignored':
        return 'xmark'
      default:
        return 'check'
    }
  })

  const isActive = computed(() => action?.status === 'active')

  function openAction(action: Action) {
    openModal('actionModal', {
      props: {
        action,
        onClose: () => {
          // refresh actions
        },
      },
    })
  }

  return {
    actionStatusIcon,
    isActive,
    openAction,
  }
}
