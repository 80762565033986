export function useFlowPaywall(flow: Flow) {
  const { planLevel, isBelowOrgFlowLimit } = useCurrentSubscription()
  const { triggerPaywall } = usePaywall()

  const shouldBlockPageAction = computed(() => {
    return (
      flow.planLevel > 0 &&
      (flow.planLevel > planLevel.value || !isBelowOrgFlowLimit.value)
    )
  })

  async function confirmValidSubscriptionPlan(
    onThroughPaywall?: () => any,
    onAfterClose?: () => void,
  ) {
    if (shouldBlockPageAction.value) {
      return triggerPaywall(onThroughPaywall, onAfterClose)
    } else if (onThroughPaywall) {
      return onThroughPaywall()
    }
  }

  return { confirmValidSubscriptionPlan, shouldBlockPageAction, triggerPaywall }
}
