<template>
  <AtroContent
    :tabbable="isActive"
    :class="[
      'relative w-full space-x-3 rounded-xl px-2 py-4 transition-all focus-visible:ring-2 sm:p-4',
      { 'group cursor-pointer shadow-card': isActive },
      { border: !isActive },
    ]"
    items="center"
    wrap="nowrap"
    @click="openAction(action)"
    @keydown.space.prevent="openAction(action)"
  >
    <AtroContent
      :class="[
        'size-6 rounded-full border-2 transition-opacity group-active:opacity-75',
        {
          'border-atro-purple': isActive,
          'border-atro-gray': !isActive,
        },
      ]"
      items="center"
      justify="center"
    >
      <AtroIcon
        :class="[
          'size-3 transition-opacity group-hover:opacity-100',
          { 'opacity-0': isActive },
        ]"
        :name="actionStatusIcon"
      />
    </AtroContent>

    <AtroContent
      shrink
      class="w-full"
      justify="between"
      items="center"
      wrap="nowrap"
    >
      <AtroContent shrink class="pr-2" direction="col">
        <AtroHeading
          semibold
          :class="[
            'transition-colors group-hover:text-atro-purple group-active:text-atro-purple-75',
            {
              'text-atro-slate-purple': isActive,
              'text-atro-gray': !isActive,
            },
          ]"
          size="xs"
          :text="action.title"
        />
        <AtroParagraph
          v-if="action.subtitle && !$isMobile.value"
          class="text-atro-gray"
          size="sm"
          :text="action.subtitle"
        />
      </AtroContent>

      <ScorePoints
        v-if="showPoints"
        class="transition-opacity group-hover:opacity-0"
        size="sm"
        :points="action.points"
      />
      <AtroBadge
        v-if="showBadge"
        capitalize
        class="transition-opacity group-hover:opacity-0"
        :label="badgeText"
        :type="badgeType"
      />
      <AtroContent
        v-if="isActive"
        class="absolute right-4 space-x-2 text-atro-purple opacity-0 transition-opacity group-hover:opacity-100 group-active:opacity-75"
        items="center"
      >
        <AtroSpan semibold>Fix This</AtroSpan>
        <AtroIcon class="size-4" name="wrench" />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { BadgeProps } from '@atro/components'

export interface Props {
  action: Action
  viewAs: View
}

const { action, viewAs } = defineProps<Props>()
const { actionStatusIcon, isActive, openAction } = useAction(action)

const showPoints = computed(
  () => action.points && isActive.value && viewAs === 'admin',
)

const showBadge = computed(() => {
  return !isActive.value || !!badgeText.value
})

const badgeText = computed(() =>
  isActive.value ? action.badgeLabel : action.status,
)
const badgeType = computed<BadgeProps['type']>(() => action.badgeType || 'warm')
</script>
