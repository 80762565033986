import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const vueApp = nuxtApp.vueApp
  const runtimeConfig = useRuntimeConfig()
  const { isDevelopment } = useVercelBuild()

  if (import.meta.client) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      enabled: !isDevelopment.value,
      environment: (import.meta.env.VITE_SENTRY_ENV as string) || 'preview',
      release: runtimeConfig.public.GIT_RELEASE_SHA,
      integrations: [
        Sentry.browserTracingIntegration({
          router: useRouter(),
        }),
      ],
      tracesSampleRate: 0.5,
    })

    vueApp.mixin({
      errorCaptured(error: Error, vm: any, info: string) {
        Sentry.captureException(error, {
          extra: {
            componentName: vm.$options.name,
            info,
            props: vm.$props,
            state: vm.$data,
          },
        })
        return false
      },
    })
  }

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
