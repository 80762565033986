<template>
  <AtroContent class="w-full gap-2" items="strech">
    <FlowAtomSolutionCard
      v-for="solution in solutions"
      :key="solution.slug"
      :selected="model === solution.slug"
      v-bind="solution"
      @click="model = solution.slug"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as SolutionCardProps } from '@/components/flow/atom/FlowAtomSolutionCard.vue'

export interface Props {
  modelValue: string
  solutions: SolutionCardProps[]
}

defineProps<Props>()
const model = defineModel<Props['modelValue']>()
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

watchEffect(() => {
  emit('validityChange', !!model.value)
})
</script>
