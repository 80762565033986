<template>
  <AtroContent
    v-auto-animate
    class="w-full space-y-2"
    direction="col"
    wrap="nowrap"
  >
    <FlowAtomListItemSubitem
      v-for="(item, i) in subitems"
      :key="i"
      v-bind="item"
      @action="onAction"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  subitems: FlowAtomListItemSubitem[]
}

defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function onAction(action: FlowAction) {
  emit('action', action)
}
</script>
