<template>
  <AtroModal size="md">
    <template #default="{ close }">
      <AtroModalHeader
        icon-wrapped
        :icon-type
        class="!mb-0"
        :icon="confirmed ? 'check' : iconName"
        :icon-class="confirmed ? 'text-atro-highlight-green' : iconClass"
        :text="confirmed ? afterConfirmHeadingText : headingText"
        :subtext="confirmed ? afterConfirmBodyText : bodyText"
      />

      <AtroContent v-if="confirmInputText && !confirmed" class="mt-6 w-full">
        <Form input-width="full" @submit="confirm(close)">
          <FormKit
            autofocus
            type="text"
            name="deleteConfirmField"
            :label="`Type ${confirmInputText} to verify`"
            :maxlength="confirmInputText.length"
            :placeholder="`${confirmInputText}`"
            :validation="`required|matches:${confirmInputText}`"
          />
          <template #submit="{ state: { valid } }">
            <AtroButton
              :pending="isPending"
              :disabled="!valid"
              :text="confirmActionText"
            />
            <AtroButton
              v-if="cancelable"
              class="mt-6"
              type="flat"
              :text="cancelActionText"
              @click.stop.prevent="close"
            />
          </template>
        </Form>
      </AtroContent>
    </template>

    <template v-if="!confirmInputText || confirmed" #actions="{ close }">
      <AtroButton v-if="confirmed" block @click="close">{{
        `${afterConfirmButtonText}${
          afterConfirmCountdown ? ` (${countdownCount})` : ''
        }`
      }}</AtroButton>
      <template v-else>
        <slot
          :close
          :confirm
          name="confirmAction"
          :confirm-action-text="confirmActionText"
          :pending="isPending"
        >
          <AtroButton
            :pending="isPending"
            :text="confirmActionText"
            @click="confirm(close)"
          />
        </slot>
        <slot name="cancelAction">
          <AtroButton
            v-if="cancelable"
            type="transparent"
            :text="cancelActionText"
            @click="cancel(close)"
          />
        </slot>
      </template>
    </template>

    <template #betweenOverlay>
      <Suspense>
        <Confetti v-if="confetti" />
      </Suspense>
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
import type { IconWrapProps } from '@atro/components'

export interface ModalConfirmProps {
  afterConfirmBodyText?: string
  afterConfirmButtonText?: string
  afterConfirmCountdown?: boolean
  afterConfirmCountdownTimeout?: number
  afterConfirmHeadingText?: string
  bodyText?: string
  confetti?: boolean
  cancelable?: boolean
  cancelActionText?: string
  confirmActionText?: string
  confirmInputText?: string
  headingText?: string
  iconClass?: string
  iconName?: IconName
  iconType?: IconWrapProps['type']
  onConfirm?: () => boolean | Promise<boolean>
  onCancel?: () => void
}

const {
  bodyText,
  afterConfirmBodyText,
  afterConfirmCountdown,
  afterConfirmHeadingText,
  confirmInputText,
  afterConfirmButtonText = 'Done',
  afterConfirmCountdownTimeout = 3,
  cancelable = true,
  cancelActionText = 'Cancel',
  confirmActionText = 'Yes',
  headingText = 'Are you sure?',
  iconName = 'triangle-exclamation',
  onCancel = () => null,
  onConfirm,
} = defineProps<ModalConfirmProps>()

const toast = useToast()

const coundownIntervalRef = ref()
const countdownCount = ref(afterConfirmCountdownTimeout)
const confirmed = ref(false)
const isPending = ref(false)

const hasAfterConfirmContent = computed(
  () => !!afterConfirmBodyText || !!afterConfirmHeadingText,
)

const cancel = (close: (params?: any) => void) => {
  onCancel()
  close()
}

const confirm = async (close: (params?: any) => void) => {
  if (!onConfirm) return close()
  isPending.value = true
  try {
    const result = await onConfirm()
    if (result) {
      if (hasAfterConfirmContent.value) {
        confirmed.value = true

        if (afterConfirmCountdown) {
          coundownIntervalRef.value = setInterval(() => {
            if (countdownCount.value <= 1) {
              close()
              clearInterval(coundownIntervalRef.value)
              countdownCount.value = afterConfirmCountdownTimeout
            } else {
              countdownCount.value -= 1
            }
          }, 1000)
        }
      } else {
        close()
      }
    }
  } catch (e: any) {
    toast.error(e.message || 'An error occured!')
  }
  isPending.value = false
}

const reset = () => {
  confirmed.value = false
  isPending.value = false
  clearInterval(coundownIntervalRef.value)
}
onUnmounted(reset)
</script>
