export default function useShareUrl() {
  const copyText = useCopyText()
  const redirectAfter = useCookie('redirectAfter')

  const hasRedirectAfter = computed(() => redirectAfter.value)

  function clearStoredRedirectUrl() {
    redirectAfter.value = undefined
  }

  function copyUrl(toastMessage?: string) {
    let unscopedUrl = window.location.href.replace(/\/orgs\/.+?(?=\/|\?)/gm, '')
    if (unscopedUrl.includes('?')) {
      unscopedUrl += '&redirectAfter=true'
    } else {
      unscopedUrl += '?redirectAfter=true'
    }
    copyText(unscopedUrl, toastMessage)
  }

  return { clearStoredRedirectUrl, copyUrl, hasRedirectAfter, redirectAfter }
}
