export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('scroller', {
    beforeMount(el: HTMLElement, binding) {
      el.classList.add('scroller')
      if (binding.value?.inset) {
        el.classList.add('scroller__inset')
      }
      if (binding.value?.warm) {
        el.classList.add('scroller__warm')
      }
    },
    beforeUnmount(el) {
      clearInterval(el.interval)
    },
    mounted(el, binding) {
      const { height: scrollElHeight } = useElementSize(el)

      el.interval = setInterval(() => {
        if (scrollElHeight.value) {
          const childEl = [...el.children].find((_el) => {
            return _el.scrollHeight > Math.ceil(scrollElHeight.value)
          })
          if (childEl) {
            if (!childEl.classList.contains('scroller-push'))
              childEl.classList.add('scroller-push')
          } else {
            el.querySelector('scroller-push')?.classList.remove('scroller-push')
          }
        }
      }, 100)
    },
  })
})
