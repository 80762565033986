<template>
  <AtroModal size="lg">
    <template #betweenOverlay>
      <Suspense>
        <Confetti v-if="upgraded" />
      </Suspense>
    </template>

    <template #default="{ close }">
      <template v-if="!upgraded">
        <AtroModalHeader
          :subtext
          text-size="lg"
          subtext-class="text-atro-red text-lg"
          :text="headerText"
        />

        <AtroContent class="w-full" direction="col" wrap="nowrap">
          <AtroContent
            class="w-full gap-6"
            justify="center"
            wrap="nowrap"
            :direction="$isMobile.value ? 'col-reverse' : 'row'"
          >
            <SubscriptionCard current plan-type="free" />
            <SubscriptionCard plan-type="pro" @upgrade="onUpgrade(close)" />
          </AtroContent>
        </AtroContent>
      </template>

      <AtroContent v-else class="w-full" direction="col">
        <AtroModalHeader
          icon-wrapped
          icon="rocket-launch"
          icon-class="banner-gradient text-white"
          text="Welcome to Atro Pro!"
        />
      </AtroContent>
    </template>

    <template v-if="upgraded" #actions="{ close }">
      <AtroButton block text="Get started!" @click="close" />
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalSubscriptionUpgradeProps {
  onCloseWithoutUpgrade?: () => void
  onUpgradeConfirm?: () => void
}

const props = defineProps<ModalSubscriptionUpgradeProps>()

const globalState = useGlobalState()
const { isFreePlan, isTrialExpired, trialRemaingDays } =
  useCurrentSubscription()

const upgraded = ref(false)

const headerText = computed(() => {
  if (isFreePlan.value) {
    return 'Upgrade to Pro to get more security'
  } else if (isTrialExpired.value) {
    return 'Your Atro Pro trial has ended'
  } else {
    return 'Lock in Atro Pro and keep the power'
  }
})

const subtext = computed(() => {
  if (isFreePlan.value) {
    return 'You are currently on the Free plan'
  } else if (isTrialExpired.value) {
    return 'Your trial has ended'
  } else if (trialRemaingDays.value) {
    return `${trialRemaingDays.value} left on your free trial of Atro Pro`
  }
  return ''
})

async function onUpgrade(closeModal: () => void) {
  open('https://share.hsforms.com/1RM-VUIcbRIWSBPKwfTSvPQddip1', '_blank')
  closeModal()
}

onMounted(() => {
  globalState.value.upgradeModalShown = true
})

onUnmounted(() => {
  globalState.value.upgradeModalShown = false
  if (upgraded.value) {
    if (props.onUpgradeConfirm) props.onUpgradeConfirm()
  } else {
    if (props.onCloseWithoutUpgrade) props.onCloseWithoutUpgrade()
  }
})
</script>
