<template>
  <AtroModal>
    <AtroModalHeader
      text="Upload Your Team by CSV"
      subtext="Fill out our CVS template then upload it below"
    />

    <AtroModalContent class="mt-8 space-y-8">
      <a href="/files/Bulk User Invite.csv" download>
        <AtroButton
          icon-right="download"
          text="Download CSV Template"
          type="fill"
        />
      </a>

      <AtroDropzone @on-files="onFiles" @reset="reset" />

      <AtroContent
        v-if="emails"
        class="w-full rounded-2xl bg-atro-warm-gray-2 p-4"
        direction="col"
        items="center"
      >
        <AtroSpan class="text-atro-gray" semibold
          >We found the following {{ emails.length }} emails</AtroSpan
        >
        <AtroDivider />
        <div v-auto-animate class="w-full space-y-2 px-2">
          <AtroContent
            v-for="(email, i) in emails"
            :key="email"
            class="group w-full rounded-lg bg-white px-4 py-2"
            justify="between"
            items="center"
          >
            <AtroSpan class="text-atro-dark-purple" size="sm" :text="email" />

            <AtroButton
              class="opacity-0 transition-opacity group-hover:opacity-100"
              size="sm"
              type="flat"
              @click="removeEmail(i)"
            >
              <AtroIcon class="h-4 w-4" name="trash" />
            </AtroButton>
          </AtroContent>
        </div>
      </AtroContent>
    </AtroModalContent>

    <template #actions="{ close }">
      <AtroButton
        block
        :pending
        text="Import"
        :disabled="importDisabled"
        @click="importUsers(close)"
      />
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
import { parse } from 'csv-parse/browser/esm'

export interface ModalImportUsersProps {
  onInviteUsers: (emails: string[]) => Promise<any>
}

const props = defineProps<ModalImportUsersProps>()

const { pending, withPending } = usePendingWrap()

const emails = ref<string[]>()
const importDisabled = computed(() => !emails.value?.length)

async function onFiles(files: File[]) {
  const csvFile = files[0]
  const data = await csvFile.text()

  parse(data, { delimiter: [';', ','] }, (e, csv: string[][]) => {
    if (csv?.length) {
      const headerRow = csv[0]
      // attempt to find email in header
      let emailColumnIndex = headerRow?.findIndex((headerCol) =>
        headerCol.toLowerCase().includes('email'),
      )
      // if not column has 'email' in it then we assume no header is defined
      const hasHeader = emailColumnIndex >= 0
      const csvData = hasHeader ? csv.slice(1) : csv.slice(0)
      const firstDataRow = csvData[0]
      // if no email column in header was found then look at data and find col with a @ symbol
      if (emailColumnIndex < 0) {
        emailColumnIndex = firstDataRow.findIndex((col) => col.includes('@'))
      }
      if (emailColumnIndex >= 0) {
        emails.value = csvData
          .map((row) => row[emailColumnIndex].trim())
          .filter(Boolean)
      }
    }
  })
}

function reset() {
  emails.value = undefined
}

function removeEmail(index: number) {
  emails.value?.splice(index, 1)
}

async function importUsers(close: () => void) {
  if (emails.value) {
    await withPending(props.onInviteUsers(emails.value))
  }
  reset()
  close()
}
</script>
