<template>
  <AtroCard
    class="relative flex-1 !px-4 !py-0"
    direction="col"
    justify="between"
    :clickable="!!action"
    @click="() => action && $emit('action', action)"
  >
    <AtroContent class="w-full gap-2 pr-8" items="center" wrap="nowrap">
      <AtroHeading
        semibold
        :class="[_countColor]"
        size="xxl"
        :text="String(count)"
      />
      <AtroSpan
        semibold
        class="text-atro-slate-purple"
        :text="title"
        size="lg"
      />
    </AtroContent>

    <AtroIcon
      v-if="icon"
      :class="['absolute right-4 top-4 size-6', _iconColor]"
      :name="icon"
    />

    <AtroParagraph
      v-if="body"
      size="xs"
      :class="['mt-2 leading-4', bodyTextColor]"
      :text="body"
    />

    <AtroContent v-if="action" class="mt-1 w-full" justify="between">
      <div />
      <AtroButton size="sm" type="flat" :text="actionLabel" />
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
type ColorOption = 'blue' | 'gray' | 'green' | 'purple' | 'red' | 'yellow'

type RollupCardAction = {
  type: 'filter'
  data: any
}

export interface RollupCardProps {
  count: number | string
  title: string

  action?: RollupCardAction
  actionLabel?: string
  body?: string
  countColor?: ColorOption
  icon?: IconName
  iconColor?: ColorOption
}

const { countColor, iconColor } = defineProps<RollupCardProps>()
defineEmits<{
  action: [action: RollupCardAction]
}>()

const _countColor = computed(() =>
  countColor || iconColor
    ? `text-atro-${countColor || iconColor}`
    : 'text-primary',
)
const _iconColor = computed(() =>
  iconColor || countColor
    ? `text-atro-${iconColor || countColor}`
    : 'text-primary',
)
const bodyTextColor = computed(() =>
  countColor === 'red' ? 'text-atro-red' : 'text-atro-gray',
)
</script>
