<template>
  <AtroContent
    class="w-full"
    wrap="nowrap"
    :direction="direction"
    :justify="justify"
    :items="items"
  >
    <FlowComponent
      v-for="config in components"
      :config
      :data
      :deps
      :key="config.name"
      @action="onAction"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  components: FlowComponentConfig[]

  data?: Record<string, any>
  deps?: Record<string, any>

  direction?: 'col' | 'row'
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  items?: 'start' | 'end' | 'center' | 'baseline' | 'strech'
}

const { direction = 'col' } = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const onAction = (action: FlowAction) => {
  emit('action', action)
}
</script>
