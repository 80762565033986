<template>
  <AtroContent
    ref="app-loading-container"
    class="fixed z-max h-full w-full bg-atro-warm-gray-2"
    direction="col"
    items="center"
    justify="center"
  >
    <AtroIcon
      ref="logoRef"
      class="size-24 text-atro-purple sm:size-28"
      name="atro-logo-vertical"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const globalState = useGlobalState()
const appLoadingRef = templateRef('app-loading-container')

onMounted(() => {
  // GSAP animation for the logo
  gsap.to(appLoadingRef.value?.$el, {
    opacity: 0,
    scale: 2,
    duration: 0.5,
    ease: 'power2.inOut',
    onComplete: () => {
      globalState.value.isLoading = false
    },
  })
})
</script>
